/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { stripslashes } from "../Helpers/SettingHelper";
import Slider from "react-slick";
class MenuNavigation extends Component {
  constructor(props) {
    super(props);
    var outletType = this.props.match.path;
    if (
      outletType === "/menu" ||
      outletType === "/menu/:slugType/:slugValue"
    ) {
      outletType = "menu";
    }
    this.state = {
      navigateMenu: [],
      outletType: outletType,
    };
  }

  componentWillReceiveProps(pdtProps) {}

  navigateMenuList() {
    let navigateMenuArr = this.props.navigateMenu;
    var selectedNav = this.props.productState.selectedNavigation;

    var selectedMainCatId = this.props.productState.selectedMainCatId;
    var totalNav = 0;
    var mainMenu = "";

    if (Object.keys(navigateMenuArr).length > 0) {
      mainMenu = navigateMenuArr.map((loaddata, index) => {
        var checkCategory = 0;
        if (this.state.outletType === "menu") {
          if (selectedMainCatId === loaddata.pro_cate_main_category_id) {
            checkCategory = 1;
          }
        } else {
          checkCategory = 1;
        }
        if (checkCategory === 1) {
          totalNav++;
          if (index <= 7) {
            if (loaddata.menu_type == "main") {
              return (
                <div
                  key={index + 1}
                  className={
                    selectedNav === loaddata.pro_cate_slug
                      ? "menu-list-intvl active"
                      : "menu-list-intvl"
                  }
                >
                  <Link
                    to={"/" +
                    this.state.outletType +
                    "/category/" +
                    loaddata.pro_cate_slug}
                    className="menu-title-link"
                    onClick={(e) => {
                      this.goCategoryProducts(loaddata.pro_cate_slug);
                    }}
                    title={loaddata.menu_custom_title}
                  >
                    {stripslashes(loaddata.menu_custom_title.toLowerCase())}
                  </Link>
                </div>
              );
            } else if (loaddata.menu_type == "sub") {
              return (
                <div
                  key={index + 1}
                  className={
                    selectedNav === loaddata.pro_subcate_slug
                      ? "menu-list-intvl active"
                      : "menu-list-intvl"
                  }
                >
                  <Link
                    to={"/products/subcategory/" + loaddata.pro_subcate_slug}
                    className="menu-title-link"
                    onClick={(e) => {
                      this.goCategoryProducts(loaddata.pro_subcate_slug);
                    }}
                    title={loaddata.menu_custom_title}
                  >
                    {stripslashes(loaddata.menu_custom_title.toLowerCase())}
                  </Link>
                </div>
              );
            }
          }
        }
      });

      if (totalNav === 0 && selectedMainCatId !== "") {
        $(
          ".product-cconmid-lhs, .product-cms-content-bottom, .product-menu-listing"
        ).hide();
        $(".productpage-main-div").addClass("pantry-work-in-progress");
        mainMenu = (
          <div className="pantry-comming-soon">
            Work in Progress.....
            <br />
            Coming Soon !!
          </div>
        );
      }
      setTimeout(function () {
        if ($(".owl-stage-outer .owl-stage .owl-item .owl-stage").length > 0) {
          $(".owl-stage-outer .owl-stage .owl-item .owl-stage").each(
            function () {
              $(this).parents(".owl-item").hide();
            }
          );
        }
      }, 100);
      return mainMenu;
    } else {
      return;
    }
  }

  navigateMobileMenuList() {
    let navigateMenuArr = this.props.navigateMenu;
    var selectedNav = this.props.productState.selectedNavigation;

    if (Object.keys(navigateMenuArr).length > 0) {
      const mainMenu = navigateMenuArr.map((loaddata, index) => {
        if (loaddata.menu_type == "main") {
          return (
            <div
              key={index + 1}
              className={
                selectedNav === loaddata.pro_cate_slug
                  ? "menu-list-intvl active"
                  : "menu-list-intvl"
              }
            >
              <Link
                to={"/menu/category/" + loaddata.pro_cate_slug}
                className="menu-title-link"
                title={loaddata.menu_custom_title}
              >
                {stripslashes(loaddata.menu_custom_title.toLowerCase())}
              </Link>
            </div>
          );
        } else if (loaddata.menu_type == "sub") {
          return (
            <div
              key={index + 1}
              className={
                selectedNav === loaddata.pro_subcate_slug
                  ? "menu-list-intvl active"
                  : "menu-list-intvl"
              }
            >
              <Link
                to={"/menu/subcategory/" + loaddata.pro_subcate_slug}
                className="menu-title-link"
                onClick={(e) => {
                  this.goCategoryProducts(loaddata.pro_subcate_slug);
                }}
                title={loaddata.menu_custom_title}
              >
                {stripslashes(loaddata.menu_custom_title.toLowerCase())}
              </Link>
            </div>
          );
        }
      });

      return mainMenu;
    } else {
      return;
    }
  }
  navigateMenuListHidden() {
    let navigateMenuArr = this.props.navigateMenu;
    var selectedNav = this.props.productState.selectedNavigation;
    var selectedMainCatId = this.props.productState.selectedMainCatId;
    var i = 0;
    if (Object.keys(navigateMenuArr).length > 0) {
      const mainMenu = navigateMenuArr.map((loaddata, index) => {
        var checkCategory = 0;
        if (this.state.outletType === "menu") {
          if (selectedMainCatId === loaddata.pro_cate_main_category_id) {
            checkCategory = 1;
          }
        } else {
          checkCategory = 1;
        }
        if (checkCategory === 1) {
          if (index > 7) {
            i++;
            if (loaddata.menu_type == "main") {
              return (
                <li
                  key={index + 1}
                  className={
                    selectedNav === loaddata.pro_cate_slug
                      ? "menu-list-intvl active"
                      : "menu-list-intvl"
                  }
                >
                  <Link
                    to={"/menu/category/" + loaddata.pro_cate_slug}
                    className="menu-title-link"
                    onClick={(e) => {
                      this.goCategoryProducts(loaddata.pro_cate_slug);
                    }}
                    title={loaddata.menu_custom_title}
                  >
                    {stripslashes(loaddata.menu_custom_title.toLowerCase())}
                  </Link>
                </li>
              );
            } else if (loaddata.menu_type == "sub") {
              return (
                <li
                  key={index + 1}
                  className={
                    selectedNav === loaddata.pro_subcate_slug
                      ? "menu-list-intvl active"
                      : "menu-list-intvl"
                  }
                >
                  <Link
                    to={"/menu/subcategory/" + loaddata.pro_subcate_slug}
                    className="menu-title-link"
                    onClick={(e) => {
                      this.goCategoryProducts(loaddata.pro_subcate_slug);
                    }}
                    title={loaddata.menu_custom_title}
                  >
                    {stripslashes(loaddata.menu_custom_title.toLowerCase())}
                  </Link>
                </li>
              );
            }
          }
        }
      });
      setTimeout(function () {
        console.log(
          $(".more-menu .menu-list-intvl.active a span").length,
          "length"
        );
        if ($(".more-menu .menu-list-intvl.active a").length > 0) {
          /* $(".more-menu .more-menu-name").html(
            $(".more-menu .menu-list-intvl.active a").text()
          ); */
          $(".more-menu .more-menu-name").html("View More");
        } else {
          $(".more-menu .more-menu-name").html("View More");
        }
      }, 100);
      if (i > 0) {
        $(".more-menu").show();
      } else {
        $(".more-menu").hide();
        $(".menu-section-left-inner").addClass('more-menu-avai');
      }
      return mainMenu;
    } else {
      return;
    }
  }

  goCategoryProducts(selectedNavigation) {
    $(document).find('.closeProductDetails').trigger('click');
    this.setState({ selectedNavigation: selectedNavigation });
        if($(document).find("#pro_cat_" + selectedNavigation).length > 0){
            $("html, body").animate(
              {
                scrollTop: $(document).find("#pro_cat_" + selectedNavigation).offset().top - 100,
              },
              1000
            );
            $(document).find('body').click();
        }
    $(".more-menu-parent, .more_categor_info").removeClass("active");
    $(".more_categor_info").hide();
  }

  render() {
    var settingsGallery = {
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
        responsive: [
    {
      breakpoint: 580,
      settings: {
        slidesToShow: 2,
      }
    }
  ]
    };
    return (
      <>
        <div className="menu-section-left">
         
            <div className="menu-section-left-inner">
              {this.navigateMenuList()}
            </div>
          
            <div className="more-menu" style={{ display: "none" }}>
              <div className="more-menu-parent">
                <span className="more-menu-name">view More</span>
                <label className="open_more_category">
                  {" "}
                  <i className="fa fa-angle-double-down"></i>{" "}
                </label>
              </div>
              <ul style={{ display: "none" }} className="more_categor_info">
                {this.navigateMenuListHidden()}
              </ul>
            </div>
          
        </div>
        <div className="menu-section-mobile-inner">
        </div>
      </>
    );
  }
}

export default MenuNavigation;
