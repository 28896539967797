/* eslint-disable */
import React, { Component } from "react";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import cookie from "react-cookies";
import { connect } from "react-redux";
import { GET_GLOBAL_SETTINGS, GET_REQUESTPAGEDATA } from "../../actions";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
var Parser = require("html-react-parser");
class Pages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pagedata: [],
      pagedetail: "",
      pagetitle_txt: "",
      pageSlug: "",
    };

    if (
      this.props.match.params !== "" &&
      this.props.match.params.page_slug !== undefined
    ) {
      var page_slug = this.props.match.params.page_slug;
    } else {
      var page_slug = this.props.match.url.replace(/\\|\//g, "");
    }
    this.props.getSettings();
    this.props.getRequestpage(page_slug);
  }

  componentDidMount() {
    $(".dvLoadrCls").show();
    cookie.remove("selectedMainCat", { path: "/" });
    setTimeout(function () {
      $(".test-popup-link").magnificPopup({ type: "image" });
    }, 2000);

    $(document).ready(function () {
      $(document).on("touchstart", ".carousel", function (event) {
        const xClick = event.originalEvent.touches[0].pageX;
        $(this).one("touchmove", function (event) {
          const xMove = event.originalEvent.touches[0].pageX;
          const sensitivityInPx = 5;

          if (Math.floor(xClick - xMove) > sensitivityInPx) {
            $(this).carousel("next");
          } else if (Math.floor(xClick - xMove) < -sensitivityInPx) {
            $(this).carousel("prev");
          }
        });
        $(this).on("touchend", function () {
          $(this).off("touchmove");
        });
      });
    });
  }

  componentDidUpdate(prevProps) {
    // alert("demo")
    window.scrollTo(0, 0);
    if (this.props.match.params !== prevProps.match.params) {
      if (
        this.props.match.params !== "" &&
        this.props.match.params.page_slug !== undefined
      ) {
        var page_slug = this.props.match.params.page_slug;
      } else {
        var page_slug = this.props.match.url.replace(/\\|\//g, "");
      }

      // this.props.getSettings();
      this.props.getRequestpage(page_slug);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.match.path !== this.props.match.path) {
      if ($(".trigger_menu").length > 0) {
        $(".trigger_menu").toggleClass("active");
        if ($(".hmenu_list").hasClass("open")) {
          $(".mega_menu").slideUp();
        }
        $(".hmenu_list").toggleClass("open");
      }
      var pageslug = nextProps.match.path.replace(/\\|\//g, "");
      this.props.getRequestpage(pageslug);
    }

    if (nextProps.pagedata !== this.state.pagedata) {
      $(".dvLoadrCls").fadeOut(2000);
      var pageDetails = "";
      var pageTitleTxt = "";
      var pageSlug = "";
      if (Object.keys(nextProps.pagedata).length > 0) {
        var pagedataTxt = nextProps.pagedata[0].cmspage_description;
        pageTitleTxt = nextProps.pagedata[0].cmspage_title;
        pageDetails = pagedataTxt !== "" ? Parser(pagedataTxt) : "";
        pageSlug = nextProps.pagedata[0].cmspage_slug;
      }

      console.log(pageTitleTxt, "pageTitleTxt");

      this.setState({
        pagedata: nextProps.pagedata,
        pagedetail: pageDetails,
        pagetitle_txt: pageTitleTxt,
        pageSlug: pageSlug,
      });
    }
  }
  sateValChange = () => {};
  render() {
    return (
      <div className="pagesList-main-div">
        {/* Header start */}
        <Header sateValChange={this.sateValChange} />
        {/* Header End */}

        <div className="common-inner-blckdiv">
          <div className="common-inner-banner">
            <p>{this.state.pagetitle_txt}</p>
          </div>
        </div>

        {this.state.pageSlug === "our-story" ? (
          <div className="container">{this.state.pagedetail}</div>
        ) : (
          <div className="cms-page">
            <div className="container-one cms-content">
              <div className="container cms-content">
                {this.state.pagedetail}
              </div>
            </div>
          </div>
        )}

        <Footer />
        <div id="dvLoading" className="dvLoadrCls"></div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  var pagedataRst = Array();
  if (Object.keys(state.pagedata).length > 0) {
    if (state.pagedata[0].status === "ok") {
      pagedataRst = state.pagedata[0].result_set;
    }
  }

  return {
    globalsettings: state.settings,
    pagedata: pagedataRst,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSettings: () => {
      dispatch({ type: GET_GLOBAL_SETTINGS });
    },
    getRequestpage: (slug) => {
      dispatch({ type: GET_REQUESTPAGEDATA, slug });
    },
  };
};

Pages.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Pages));
