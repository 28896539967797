/* Live */
import cookie from 'react-cookies';

var isSubdomain = function(url) {
    var regex = new RegExp(/^([a-z]+\:\/{2})?([\w-]+\.[\w-]+\.\w+)$/);
    return !!url.match(regex);
}
console.log(window.location.host)
console.log(isSubdomain(window.location.host))

var isSubdomains = function(url) {
return url.split('.').length > 2;
}
console.log(isSubdomains(window.location.host))

if (!window.location.host.startsWith("www") && !isSubdomain(window.location.host) && !isSubdomains(window.location.host)){
   var hostname = "www." + window.location.hostname;
}else{
   var hostname = window.location.hostname;
}
console.log(hostname)

export const deliveryId = "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11";
export const pickupId = "718B1A92-5EBB-4F25-B24D-3067606F67F0";
export const cateringId = "EB62AF63-0410-47CC-9464-038E796E28C4";
export const reservationId = "79FA4C7F-75A1-4A95-B7CE-81ECA2575363";
export const dineinId = "EF9FB350-4FD4-4894-9381-3E859AB74019";

export const apiUrl = "https://ccpl.ninjaos.com/api/";
export const apiUrlV2 = "https://ccpl.ninjaos.com/apiv2/";
export const apiUrlPro = "https://ccpl.ninjaos.com/ninjapro/";
export const apiUrlCtrng = "https://ccpl.ninjaos.com/cateringv2/";
export const apiUrlNotify = "https://ccpl.ninjaos.com/Pushorder/";
export const timThumpUrl = "https://ccpl.ninjaos.com/timthumb.php?src=";
export const mediaUrl = "https://ccpl.ninjaos.com/media/dev_team/";
export const blogImageUrl = "https://ccpl.ninjaos.com/media/dev_team/blog/";
export const tagImageUrl = "https://ccpl.ninjaos.com/media/dev_team/tag/";
export const proFolderUrl = "https://ccpl.ninjaos.com/media/dev_team/products/";
export const outletUrl = "https://ccpl.ninjaos.com/media/dev_team/outlet/";

var domainValues = {
  localhost: {
    appId: "8ABBC2B0-E196-44DA-B92C-FB95FBE12C1A",
    baseUrl: "http://localhost:3000/",
    CountryTxt: "Singapore",
    productNoImg: "/img/product-noimg.jpg",
    companyname: "Sinstant",
    fbAppId: "748743203074285",
    stripeEmail: "dev@jankosoft.com",
    stripeReference: "sinstant",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultoutlet: 433,
    currencySybmol: "S$",
    headermenu: "xinstant-header-menu",
    foldername: "xinstant",
    instaToken:
      "IGQVJXVVBvS205QXppOFFpSzlrd3dWRXc4Sk9JcVF5RUkxbmJxd3JHYV9RMHpNc001bHA1MU5xQnRoX2k3SV94MHRtMlRvTTVzNk1iQW9tak5fRWhORURqT0l0SEFGcGtqYlN2OFhzc2NRVDN6eHc0SgZDZD",
    mapcountry: "sg",
  },
  "sinstant.promobuddy.asia": {
    appId: "8ABBC2B0-E196-44DA-B92C-FB95FBE12C1A",
    baseUrl: "https://www.sinstant.promobuddy.asia/",
    CountryTxt: "Singapore",
    productNoImg: "/img/product-noimg.jpg",
    companyname: "Sinstant",
    fbAppId: "748743203074285",
    stripeEmail: "dev@jankosoft.com",
    stripeReference: "sinstant",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultoutlet: 433,
    currencySybmol: "S$",
    headermenu: "xinstant-header-menu",
    foldername: "xinstant",
    instaToken:
      "IGQVJXVVBvS205QXppOFFpSzlrd3dWRXc4Sk9JcVF5RUkxbmJxd3JHYV9RMHpNc001bHA1MU5xQnRoX2k3SV94MHRtMlRvTTVzNk1iQW9tak5fRWhORURqT0l0SEFGcGtqYlN2OFhzc2NRVDN6eHc0SgZDZD",
    mapcountry: "sg",
  },
  "sinstant.com.sg": {
    appId: "8ABBC2B0-E196-44DA-B92C-FB95FBE12C1A",
    baseUrl: "https://www.sinstant.com.sg/",
    CountryTxt: "Singapore",
    productNoImg: "/img/product-noimg.jpg",
    companyname: "Sinstant",
    fbAppId: "748743203074285",
    stripeEmail: "dev@jankosoft.com",
    stripeReference: "sinstant",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultoutlet: 433,
    currencySybmol: "S$",
    headermenu: "xinstant-header-menu",
    foldername: "xinstant",
    instaToken:
      "IGQVJXVVBvS205QXppOFFpSzlrd3dWRXc4Sk9JcVF5RUkxbmJxd3JHYV9RMHpNc001bHA1MU5xQnRoX2k3SV94MHRtMlRvTTVzNk1iQW9tak5fRWhORURqT0l0SEFGcGtqYlN2OFhzc2NRVDN6eHc0SgZDZD",
    mapcountry: "sg",
  }
};

if(!domainValues[hostname]){
  hostname = hostname.replace('www.','');
}


export const appId = domainValues[hostname].appId;
export const stripeImage =
  "img/" + domainValues[hostname].foldername + "/stripe_logo.png";
export const productNoImg = domainValues[hostname].productNoImg;
export const companyname = domainValues[hostname].companyname;
export const fbAppId = domainValues[hostname].fbAppId;
export const stripeEmail = domainValues[hostname].stripeEmail;
export const stripeReference = domainValues[hostname].stripeReference;
export const stripeDesc = domainValues[hostname].stripeDesc;

export const defaultoutlet = domainValues[hostname].defaultoutlet;
export const headermenu = domainValues[hostname].headermenu;
export const instaToken = domainValues[hostname].instaToken;
export const foldername = domainValues[hostname].foldername;
export const mainLogo =
  "/img/" + domainValues[hostname].foldername + "/logo.png";
export const noimage =
  "/img/" + domainValues[hostname].foldername + "/no-img-product.jpg";
  console.log(noimage);
export const productImg =
  "/img/" + domainValues[hostname].foldername + "/noimg-400x400.png";
export const noimage470 =
  "/img/" + domainValues[hostname].foldername + "/noimg-470x240.jpg";
export const baseUrl = domainValues[hostname].baseUrl;



export const stripeCurrency     = (typeof (cookie.load("clientCurrencyName"))!="undefined"?cookie.load("clientCurrencyName"):domainValues[hostname].stripeCurrency);
export const currencySybmol = (typeof (cookie.load("clientCurrency"))!="undefined"?cookie.load("clientCurrency"):domainValues[hostname].currencySybmol);
export const CountryTxt = (typeof (cookie.load("clientCountry"))!="undefined"?cookie.load("clientCountry"):domainValues[hostname].CountryTxt);
export const mapcountry = domainValues[hostname].mapcountry;