/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import Axios from "axios";
import Slider from "react-slick";
import cookie from "react-cookies";
import { connect } from "react-redux";
import { format } from "date-fns";
import { isNull } from "underscore";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import OwlCarousel from "react-owl-carousel2";
import "../../common/css/owl.carousel.css";
import {
  appId,
  tagImageUrl,
  apiUrl,
  apiUrlV2,
  deliveryId,
  baseUrl,
  noimage,
  currencySybmol
} from "../Helpers/Config";
import {
  getReferenceID,
  stripslashes,
  showPriceValue,
  showLoader,
  hideLoader,
  showCustomAlert,
  showCartLst,
  showAlert,
  removePromoCkValue,
  shorten,
  createScriptForAddToCart,
} from "../Helpers/SettingHelper";
import profileImg from "../../common/images/user-profile.png";
import likedark from "../../common/images/cat-item-like-bw.png";
import cartLikeActive from "../../common/images/cat-item-like-bw-active.png";
import Chefimg from "../../common/images/chef.png";
import {
  GET_PRODUCT_DETAIL,
  GET_ADDONPRODUCT,
  GET_FAVOURITE,
} from "../../actions";
import { Accordion, AccordionItem } from "react-light-accordion";
import "react-light-accordion/demo/css/index.css";
const isEqual = require("react-fast-compare");
var Parser = require("html-react-parser");
var qs = require("qs");
var Carousel = require("react-responsive-carousel").Carousel;
var settingsParedPro = {
  infinite: false,
  dots: true,
  arrows: false,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1191,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: false,
      },
    },
    {
      breakpoint: 850,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 680,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
var settingsProduct = {
  items: 4,
  autoplay: false,
  margin: 18,
  loop: false,
  nav: false,
  dots: true,
  responsive: {
    0: {
      items: 1,
      dots: true,
    },
    580: {
      items: 2,
    },
    780: {
      items: 3,
    },
    1280: {
      items: 4,
    },
  },
};
var settingsAddons = {
  infinite: false,
  dots: true,
  arrows: false,
  slidesToShow: 4,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1191,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: false,
      },
    },
    {
      breakpoint: 850,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 680,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
var settingsLikemore = {
  autoplay: false,
  margin: 20,
  loop: false,
  nav: false,
  dots: true,
  responsive: {
    0: {
      items: 1,
      dots: true,
    },
    480: {
      items: 2,
    },
    780: {
      items: 3,
    },
    1200: {
      items: 4,
    },
  },
};

class ProductDetail extends Component {
  constructor(props) {
    super(props);
    var selectedProSlug = "";
    if (
      this.props.match.params !== "" &&
      typeof this.props.match.params !== undefined &&
      typeof this.props.match.params !== "undefined"
    ) {
      if (
        this.props.match.params.proValue !== "" &&
        typeof this.props.match.params.proValue !== undefined &&
        typeof this.props.match.params.proValue !== "undefined"
      ) {
        selectedProSlug = this.props.match.params.proValue;
      }
    }
    var orderOutletId =
      cookie.load("orderOutletId") != "" &&
      cookie.load("orderOutletId") != undefined
        ? cookie.load("orderOutletId")
        : "";
    this.state = {
      selectedProSlug: selectedProSlug,
      navigateMenu: [],
      navigateMenuTmp: [],
      selectedCategoryName: "",
      selectedSlugType: "",
      selectedSlugValue: "",
      catNavIndex: 0,
      productdetailstatus: "",
      productdetail: [],
      productcommon: [],
      selectedProId: "",
      remaining: 30,
      product_remarks: "",
      mdfinput_value: 1,
      mdfApi_call: "Yes",
      modParentProductId: "",
      modProductPrice: "",
      modProductTotalPrice: "",
      compoinput_value: 1,
      incrCompoPrice: 0,
      compoApi_call: "Yes",
      cartTriggerFlg: "No",
      descriptionType: 1,
      varSimpleProQty: 1,
      orderOutletId: orderOutletId,
      customerId:
        cookie.load("UserId") !== "" &&
        typeof cookie.load("UserId") !== "undefined" &&
        typeof cookie.load("UserId") !== undefined
          ? cookie.load("UserId")
          : "",
      favproductID: [],
      favdeleteproductID: [],
    };

    showLoader("prodetailinner-main-div", "class");
    this.props.getProductDetail(selectedProSlug);
    this.props.getAddonProList(orderOutletId);
  }

  componentDidMount() {
    var current = this;
    $(document).click(function (e) {
      current.setState({
        nav1: current.slider1,
        nav2: current.slider2,
      });
    });
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.selectedProSlug !== nextProps.match.params.proValue) {
      this.setState(
        {
          selectedProSlug: nextProps.match.params.proValue,
          varSimpleProQty: 1,
        },
        function () {
          this.props.getProductDetail(nextProps.match.params.proValue);
          if ($(".hsearch_sec.open").length > 0) {
            $(".hsearch_trigger").trigger("click");
          }

          $("html, body").animate(
            {
              scrollTop: $(".prodetailinner-main-div").offset().top - 200,
            },
            1000
          );
        }
      );
    }
    if (this.state.productdetail !== nextProps.productdetail) {
      if (
        nextProps.productdetail !== "" &&
        nextProps.productdetail.length > 0
      ) {
        this.setState({ productdetail: nextProps.productdetail }, function () {
          this.loadCategoryProducts(
            nextProps.productdetail[0].catgory_name,
            nextProps.productdetail[0].catgory_description,
            nextProps.productdetail[0].cat_slug
          );
        });
      }
    }
  }

  addFavourite(productID, fav_flag) {
    console.log(this.state.customerId, "this.state.customerId");
    if (this.state.customerId !== "") {
      var formData = {
        app_id: appId,
        product_id: productID,
        fav_flag: fav_flag,
        customer_id: this.state.customerId,
      };
      var favproductID = this.state.favproductID;
      var favdeleteproductID = this.state.favdeleteproductID;
      if (fav_flag === "Yes") {
        favproductID.push(productID);
        const index1 = favdeleteproductID.indexOf(productID);
        if (index1 > -1) {
          favdeleteproductID.splice(index1, 1);
        }
      } else {
        const index = favproductID.indexOf(productID);
        if (index > -1) {
          favproductID.splice(index, 1);
        }
        favdeleteproductID.push(productID);
      }
      this.setState({
        favproductID: favproductID,
        favdeleteproductID: favdeleteproductID,
      });

      var postObject = qs.stringify(formData);
      this.props.getFavouriteproducts(postObject);
    } else {
      $.magnificPopup.open({
        items: {
          src: "#login-popup",
        },
        type: "inline",
      });
    }
  }

  showProductTags(tags) {
    return tags.map((item, index) => (
      <>
        {item.pro_tag_image !== "" && (
          <img
            src={tagImageUrl + item.pro_tag_image}
            alt={stripslashes(item.pro_tag_name)}
          />
        )}
        </>
    ));
  }

  productDetailsMain() {
    var proDetStatus = this.props.productdetailstatus;
    var proDetails = this.props.productdetail;

    if (Object.keys(proDetails).length > 0 && proDetStatus === "success") {
      $(".dvLoadrCls").fadeOut(2000);
      var imageSource = this.props.productcommon.image_source;
      var product_gallery_image_source =
        this.props.productcommon.product_gallery_image_source;
      setTimeout(function () {
        $("#proIndex-" + proDetails[0].product_primary_id).removeClass(
          "active"
        );
        hideLoader("comboPro-" + proDetails[0].product_slug, "Idtext");
      }, 500);

      var desc = "";
      const settings = {
        dots: true,
        arrows: false,
        dotsClass: "slick-dots slick-thumb",
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
      };

      return proDetails.map((data, index) => {
        desc =
          data.product_long_description !== "" &&
          data.product_long_description !== null
            ? stripslashes(data.product_long_description)
            : data.product_short_description !== "" &&
              data.product_short_description !== null
            ? stripslashes(shorten(data.product_short_description))
            : "";

        var comboLgth = data.set_menu_component
          ? data.set_menu_component.length
          : 0;
        var ModifLgth = data.modifiers ? data.modifiers.length : 0;
        var isCombo = data.product_type === "2" && comboLgth > 0 ? "Yes" : "No";
        var isModifier =
          data.product_type === "4" && ModifLgth > 0 ? "Yes" : "No";
        var isSimple =
          data.product_type === "1" && comboLgth === 0 && ModifLgth === 0
            ? "Yes"
            : "No";
        return (
          <div key={index}>
            <div
              className="inn-product-popup"
              key={data.product_primary_id}
              id={"product_" + data.product_primary_id}
            >
              <div className="product-go-back">
                <div className="container">
                  <a
                    href={
                      "/menu/"
                    }
                  >
                    Menu
                  </a> <span className="menu-arrow"> > </span> <a
                    href="#"
                  >
                    {this.props.match.params.slugValue.replace(/[^a-zA-Z ]/g, " ")}
                  </a>
                </div>
              </div>
              <div className="common-modal-head" style={{position:"relative"}}>
                      <Link to={"/menu/"}>
                        <button type="button" className="close">
                          X
                        </button>
                      </Link>
                    </div>
              <section className="product-details-top-info">
                <div className="container">
                  <div className="product-details-lhs">
                    {/* product image div - start */}
                    {data.product_tag_info !== "" &&
                      data.product_tag_info !== null && (
                        <div className="ribbon">
                          <span>{data.product_tag_info}</span>
                        </div>
                      )}
        
                    {this.state.customerId !== "" && (
                      <div>
                        {this.state.favdeleteproductID.indexOf(
                          data.product_primary_id
                        ) >= 0 ? (
                          <a
                            href={void 0}
                            className="like"
                            onClick={this.addFavourite.bind(
                              this,
                              data.product_primary_id,
                              "Yes"
                            )}
                          >
                            <img src={likedark} />
                            <img src={cartLikeActive} className="active-hart" />
                          </a>
                        ) : (!isNull(data.fav_product_primary_id) &&
                            typeof data.fav_product_primary_id !== undefined &&
                            typeof data.fav_product_primary_id !==
                              "undefined") ||
                          this.state.favproductID.indexOf(
                            data.product_primary_id
                          ) >= 0 ? (
                          <a
                            href={void 0}
                            className="like active"
                            onClick={this.addFavourite.bind(
                              this,
                              data.product_primary_id,
                              "No"
                            )}
                          >
                            <img src={likedark} />
                            <img src={cartLikeActive} className="active-hart" />
                          </a>
                        ) : (
                          <a
                            href={void 0}
                            className="like"
                            onClick={this.addFavourite.bind(
                              this,
                              data.product_primary_id,
                              "Yes"
                            )}
                          >
                            <img src={likedark} />
                            <img src={cartLikeActive} className="active-hart" />
                          </a>
                        )}
                      </div>
                    )}

                    <Slider {...settings}>
                      {data.image_gallery.length > 0 ? (
                        data.image_gallery.map(function (item, imgindex) {
                          return (
                            <div key={imgindex}>
                              {
                                <img
                                  src={
                                    product_gallery_image_source +
                                    "/" +
                                    item.pro_gallery_image
                                  }
                                />
                              }
                            </div>
                          );
                        })
                      ) : (
                        <div>
                          {data.image_gallery.length > 0 ? (
                            <img src={detailBG} />
                          ) : data.product_thumbnail !== "" ? (
                            <img
                              src={imageSource + "/" + data.product_thumbnail}
                            />
                          ) : (
                            <img src={noimage} />
                          )}
                        </div>
                      )}
                    </Slider>
                    {/* product image div - end */}
                    {/* Addon Product div - start */}
                    {data.paired_products.length > 0 && (
                      <div className="add-extra-paired-products">
                        <h2>Would You Like To Add?</h2>
                        <Slider {...settingsParedPro}>
                          {data.paired_products.map((item, index) => {
                            return (
                              <div>
                                <div className="paired-products-image">
                                  {item.product_thumbnail !== "" ? (
                                    <img
                                      src={
                                        imageSource +
                                        "/" +
                                        item.product_thumbnail
                                      }
                                      alt="product"
                                    />
                                  ) : (
                                    <img src={noimage} alt="product" />
                                  )}
                                </div>
                                <div className="paired-products-desc">
                                  <h4>
                                    {" "}
                                    {item.product_alias !== ""
                                      ? stripslashes(item.product_alias)
                                      : stripslashes(item.product_name)}
                                  </h4>
                                  <div className="paired-products-price-main">
                                    <div className="paired-products-price">
                                      ${item.product_price}
                                    </div>
                                    <Link
                                      to={
                                        "/menu/" +
                                        item.cat_slug +
                                        "/" +
                                        item.subcate_slug +
                                        "/" +
                                        item.product_slug
                                      }
                                    >
                                      <button>Add</button>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </Slider>
                      </div>
                    )}
                    {/* Addon Product div - end */}
                  </div>
                  <div className="product-details-rhs">
                    {/* product info div - start */}
                    <div className="prodet_baninfo">
                      <div className="inn_product_hea">
                        <h3>{stripslashes(data.catgory_name)}</h3>

                        <div className="inn_product_review">
                          <div className="inn_product_hea_left">
                            <h3>
                              {" "}
                              {data.product_alias !== ""
                                ? stripslashes(data.product_alias)
                                : stripslashes(data.product_name)}{" "}
                            </h3>
                          </div>
                        </div>
                        {data.product_tag.length > 0 && (
                          <div className="product-item-img">
                            {this.showProductTags(data.product_tag)}
                          </div>
                        )}
                        {this.state.descriptionType === 1 && (
                          <>{desc !== "" && desc !== null ? Parser(desc) : ""}</>
                        )}
                        {/*{(desc !== "" || data.product_receipe_info !== "") && (
                          <div className="product-description-tab">
                            <ul>
                              <li
                                className={
                                  this.state.descriptionType === 1
                                    ? "active"
                                    : ""
                                }
                              >
                                <a
                                  href="#"
                                  onClick={this.setCurrentDesc.bind(this, 1)}
                                >
                                  Update Your Product
                                </a>
                              </li>

                              <li
                                className={
                                  this.state.descriptionType === 2
                                    ? "active"
                                    : ""
                                }
                              >
                                <a
                                  href="#"
                                  onClick={this.setCurrentDesc.bind(this, 2)}
                                >
                                  Product Description
                                </a>
                              </li>
                            </ul>
                          </div>
                        )}*/}
                        <div className="product-description">
                          {/* this.state.descriptionType === 1 && (
                            <>
                              {desc !== "" && desc !== null ? Parser(desc) : ""}
                            </>
                          ) */}{" "}
                          {this.state.descriptionType === 2 && (
                            <>
                              {data.product_receipe_info !== "" &&
                              data.product_receipe_info !== null
                                ? Parser(data.product_receipe_info)
                                : ""}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    {/* product info div - end */}

                    {/* combo or modifier top div - start */}
                    {this.state.descriptionType === 1 && (
                      <div className="inn_product_row">
                        {isCombo === "Yes" ? this.comboProDetails(data) : ""}
                        {isModifier === "Yes"
                          ? this.modifierProDetails(data)
                          : ""}
                        {isSimple === "Yes" ? this.simpleProDetails(data) : ""}
                      </div>
                    )}
                    
                    {/* combo or modifier top div - end */}
                    <div className="product-addtional-info">
                      {data.addtional_info.length > 0 && (
                        <Accordion>
                          {data.addtional_info.map((item, index1) => {
                            return (
                              <AccordionItem
                                title={item.addtional_info_title}
                                key={index1}
                              >
                                {item.addtional_info_description !== ""
                                  ? Parser(item.addtional_info_description)
                                  : ""}
                              </AccordionItem>
                            );
                          })}
                        </Accordion>
                      )}
                      {data.rating_reviews.reviews.length > 0 ? (
                        <Accordion>
                          <AccordionItem title="Reviews">
                            <div className="review-full">
                              <ul>
                                {data.rating_reviews.reviews.map(
                                  (review, index1) => {
                                    return (
                                      <li>
                                        <div className="review-author">
                                          <div className="review-author-img">
                                            <img
                                              src={
                                                review.customer_photo !== "" &&
                                                review.review_set_anonymous ===
                                                  "0"
                                                  ? review.customer_photo
                                                  : profileImg
                                              }
                                              alt="review"
                                            />
                                          </div>
                                          <div className="review-author-content">
                                            <h4>
                                              {review.review_set_anonymous ===
                                              "0"
                                                ? review.customer_first_name
                                                : "Anonymous"}
                                            </h4>
                                            <p>{review.review_description}</p>
                                          </div>
                                        </div>
                                      </li>
                                    );
                                  }
                                )}
                              </ul>
                            </div>
                          </AccordionItem>
                        </Accordion>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  {data.paired_products.length > 0 && (
                    <div className="add-extra-paired-products add-extra-paired-products-mobile">
                      <h2>Would You Like To Add?</h2>
                      <Slider {...settingsParedPro}>
                        {data.paired_products.map((item, index) => {
                          return (
                            <div>
                              <div className="paired-products-image">
                                {item.product_thumbnail !== "" ? (
                                  <img
                                    src={
                                      imageSource + "/" + item.product_thumbnail
                                    }
                                  />
                                ) : (
                                  <img src={noimage} alt="product" />
                                )}
                              </div>
                              <div className="paired-products-desc">
                                <h4>
                                  {" "}
                                  {item.product_alias !== ""
                                    ? stripslashes(item.product_alias)
                                    : stripslashes(item.product_name)}
                                </h4>
                                <div className="paired-products-price-main">
                                  <div className="paired-products-price">
                                    ${item.product_price}
                                  </div>
                                  <Link
                                    to={
                                      "/menu/" +
                                      item.cat_slug +
                                      "/" +
                                      item.subcate_slug +
                                      "/" +
                                      item.product_slug
                                    }
                                  >
                                    <button>Add</button>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </Slider>
                    </div>
                  )}
                <section className="about-chef">
                  <div className="hoj-inner">
                    <div className="hoj-lhs">
                    <h5>About Chef</h5>
                    <h4>Two-Star Michelin Chef Cheng Kam Fu</h4>
                    <p>
                      Hong Kong Two-star Michelin Chef Cheng Kam Fu is a Michelin Star Chef since 2010. He currently holds 18 Michelin Stars in his fine dining restaurant Celebrity Cuisine in Central Hong Kong. With his skills and use of best 
                    ingredients, he successfully elevates the taste of many classic dishes to fine dining status. His restaurant are a must-visit destination not only for food lovers, but also culinary experts from all over the world.
                    </p>
                    <div className="about-chef-vm-link">
                    <a href="#">Vew More</a></div>
                    </div>
                    <div className="hoj-rhs">
                    <div className="about-left-container position-relative order-lg-2">
                    <div className="about-img-div about-img-all-div about-img-hisdiv">
                    <div className="item">
                    <figure className="rounded shadow">
                    <img src={Chefimg} alt="About Us" /></figure>
                    </div>
                    </div>
                    </div>
                    </div>
                    </div>
                    </section>
                </div>
              </section>

              <section>
                <div className="container">
                  {this.state.categoryPro}

                  {/* addon products main div - start */}
                  {Object.keys(this.props.addonproductlist).length > 0 && (
                    <div className="addonpro-slider-top">
                      <div className="chk-addonpro-title">
                        <h3>You May Also Like</h3>
                      </div>
                      <div className="detail-pg-slider">
                        <OwlCarousel options={settingsLikemore}>
                          {this.addonProductListing()}
                        </OwlCarousel>
                        <div className="home-bottom-link">
                          <a href="">
                            <strong>Shop More</strong>
                          </a>
                        </div>
                      </div>
                    </div>
                  )}
                  {/* addon products main div - end */}
                </div>
              </section>
            </div>
          </div>
        );
      });
    } else if (
      Object.keys(proDetails).length === 0 &&
      proDetStatus === "failure"
    ) {
      return (
        <div className="product-detail-empty">
          Sorry, Invalid Product Detail.{" "}
        </div>
      );
    } else {
      return "";
    }
  }

  loadCategoryProducts(catgory_name, catgory_description, cat_slug) {
    var availabilityId =
      cookie.load("defaultAvilablityId") === undefined ||
      cookie.load("defaultAvilablityId") == ""
        ? deliveryId
        : cookie.load("defaultAvilablityId");

    var orderOutletId =
      cookie.load("orderOutletId") === undefined ||
      cookie.load("orderOutletId") == ""
        ? ""
        : cookie.load("orderOutletId");
    var deliveryDate =
      cookie.load("deliveryDate") == undefined ||
      cookie.load("deliveryDate") == ""
        ? ""
        : cookie.load("deliveryDate").split("/");
    var delivery_Date = "";
    if (deliveryDate !== "") {
      delivery_Date =
        deliveryDate[2] + "-" + deliveryDate[1] + "-" + deliveryDate[0];
    } else {
      delivery_Date = format(new Date(), "yyyy-MM-dd");
    }
    var spicelPride =
      "&specialprice_applicable=orderdate&order_datetxt=" + delivery_Date;
    Axios.get(
      apiUrlV2 +
        "products/getAllProducts?app_id=" +
        appId +
        "&availability=" +
        availabilityId +
        "&category_slug=" +
        cat_slug +
        "&outletId=" +
        orderOutletId +
        spicelPride
    ).then((res) => {
      var response = res.data;
      if (response.status === "ok") {
        this.displayCategoryProducts(
          catgory_name,
          catgory_description,
          response.result_set,
          response.common
        );
      }
    });
  }

  displayCategoryProducts(
    catgory_name,
    catgory_description,
    categoryProducts,
    common
  ) {
    var categoryPro = "";
    if (categoryProducts !== "" && categoryProducts.length > 0) {
      categoryPro = (
        <section className="product-details-category-section">
          <div className="category-products-section">
            <div className="container-noneed">
            <div className="product-details-title">
              <h3>{catgory_name}</h3>
              {catgory_description !== "" && catgory_description !== null && (
                <p>{stripslashes(catgory_description)}</p>
              )}
              </div>
              <div className="category-products-main">
                <div className="products-list-ulmain">
                  <OwlCarousel options={settingsProduct}>
                    {categoryProducts.map((subcat, index) => {
                      if (subcat.subcategorie.length > 0) {
                        return subcat.subcategorie.map(
                          (productsList, index1) => {
                            if (productsList.products.length > 0) {
                              return productsList.products.map(
                                (products, index2) => {
                                  if (
                                    this.state.selectedProSlug !=
                                    products.product_slug
                                  ) {
                                    return (
                                      <div
                                        className="products-single-li"
                                        key={
                                          index + "-" + index1 + "-" + index2
                                        }
                                      >
                                        <div className="products-image-div">
                                          {products.product_thumbnail !== "" ? (
                                            <img
                                              src={
                                                common.product_image_source +
                                                "/" +
                                                products.product_thumbnail
                                              }
                                            />
                                          ) : (
                                            <img src={noimage} />
                                          )}
                                        </div>

                                        <div className="product-info-div">
                                          <div className="product-title-maindiv">
                                            <div className="product-title">
                                              <h3>
                                                {products.product_alias !== ""
                                                  ? stripslashes(
                                                      products.product_alias
                                                    )
                                                  : stripslashes(
                                                      products.product_name
                                                    )}
                                              </h3>
                                            </div>
                                          </div>

                                          <div className="product-short-description">
                                            {products.product_short_description !=
                                            ""
                                              ? Parser(
                                                  stripslashes(
                                                    shorten(products.product_short_description)
                                                  )
                                                )
                                              : Parser("")}
                                          </div>

                                          <div className="products-ordernow-action">
                                            <div className="products-ordernow-inner">
                                              <div className="product-price">
                                                {products.product_specialprice_applicable === "yes"
                                                  && ( parseFloat(products.product_price) > 0 ? (
                                                      <span className="price_disc">
                                                        {showPriceValue(products.product_price)}
                                                      </span>
                                                    )
                                                  : parseFloat(products.product_cost) > 0 && (
                                                      <span className="price_disc">
                                                        {showPriceValue(products.product_cost)}
                                                      </span>
                                                    ))}
                                                <h3>
                                                  {products.product_specialprice_applicable === "yes"
                                                    ? showPriceValue(
                                                        products.product_special_price,
                                                        "Y"
                                                      )
                                                    : showPriceValue(products.product_price)}
                                                </h3>
                                              </div>

                                              {products.product_stock > 0 ||
                                              products.product_stock ===
                                                null ? (
                                                <Link
                                                  className="button order_nowdiv smiple_product_lk disbl_href_action"
                                                  to={
                                                    "/menu/" +
                                                    productsList.pro_cate_slug +
                                                    "/" +
                                                    productsList.pro_subcate_slug +
                                                    "/" +
                                                    products.product_slug
                                                  }
                                                >
                                                  Order Now
                                                </Link>
                                              ) : (
                                                <a
                                                  className="button disabled disbl_href_action"
                                                  href="/"
                                                >
                                                  Sold Out
                                                </a>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  }
                                }
                              );
                            }
                          }
                        );
                      }
                    })}
                  </OwlCarousel>
                </div>
              </div>
            </div>
          </div>
        </section>
      );
    }
    this.setState({ categoryPro: categoryPro });
  }

  setCurrentDesc(descriptionType, event) {
    event.preventDefault();
    this.setState({ descriptionType: descriptionType });
  }

  /* show Simple product details */
  simpleProDetails(proDetailArr) {
    var modProductPrice = proDetailArr.product_price;
    var modProductTotalPrice =
      proDetailArr.product_specialprice_applicable === "yes"
        ? proDetailArr.product_special_price
        : proDetailArr.product_price;
    return (
      <div>
        <div className="prd_chosen_sub_row">
          <p className="total_price product_details_price">
            <div className="product-price">
              {proDetailArr.product_specialprice_applicable === "yes"
                && ( parseFloat(proDetailArr.product_price) > 0 ? (
                    <span className="price_disc">
                      {showPriceValue(proDetailArr.product_price, "Y")}
                    </span>
                  )
                : parseFloat(proDetailArr.product_cost) > 0 && (
                    <span className="price_disc">
                      {showPriceValue(proDetailArr.product_cost, "Y")}
                    </span>
                  ))}
            </div>
            <span id="id_price_final">
              {showPriceValue(modProductTotalPrice, "Y")}
            </span>
          </p>
          <div
            id={"proDtIndex-" + proDetailArr.product_primary_id}
            className="prd_chosen_sub_col popup_addcart_cls modfir_addcart_cls"
          >
            <div className="addcart_row prd_chosen_sub_item_left cart_update_div addcart_done_maindiv">
              <p className="sel-quality">Select Quantity</p>
              <div className="qty_bx">
                <span
                  className="qty_minus"
                  onClick={this.proQtyAction.bind(
                    this,
                    proDetailArr.product_primary_id,
                    "decr"
                  )}
                >
                  -
                </span>
                <input
                  type="text"
                  value={this.state.varSimpleProQty}
                  className="proqty_input"
                  readOnly="1"
                />
                <span
                  className="qty_plus"
                  onClick={this.proQtyAction.bind(
                    this,
                    proDetailArr.product_primary_id,
                    "incr"
                  )}
                >
                  +
                </span>
              </div>
            </div>

            <div className="prd_chosen_sub_item_right cart_update_div addcart_done_maindiv">
              <button
                onClick={this.addToCartSimple.bind(this, proDetailArr, "done")}
              >
                Order Now
              </button>
            </div>

            <div className="cart-success-msg alert alert_success mdfcart_success_msg"></div>
            <div className="cart-error-msg alert alert_danger mdfcart_error_msg"></div>
          </div>
        </div>
      </div>
    );
  }

  proQtyAction(indxFlg, actionFlg) {
    var proqtyInput = $("#proDtIndex-" + indxFlg)
      .find(".proqty_input")
      .val();
    proqtyInput = parseInt(proqtyInput);
    if (actionFlg === "decr") {
      proqtyInput = proqtyInput > 1 ? proqtyInput - 1 : proqtyInput;
    } else {
      proqtyInput = proqtyInput + 1;
    }
    this.setState({ varSimpleProQty: proqtyInput });
  }

  /* add to cart */
  addToCartSimple(productDetail, actionFlg) {
    var IndexFlg = productDetail.product_primary_id;

    var avilablityIdTxt =
      cookie.load("defaultAvilablityId") !== "" &&
      typeof cookie.load("defaultAvilablityId") !== undefined &&
      typeof cookie.load("defaultAvilablityId") !== "undefined"
        ? cookie.load("defaultAvilablityId")
        : "";
    if (avilablityIdTxt === "") {
      $.magnificPopup.open({
        items: {
          src: "#order-popup",
        },
        type: "inline",
      });
      return false;
    }
    var outltIdTxt =
      cookie.load("orderOutletId") !== "" &&
      typeof cookie.load("orderOutletId") !== undefined &&
      typeof cookie.load("orderOutletId") !== "undefined"
        ? cookie.load("orderOutletId")
        : "";
    if (this.state.orderOutletId === "") {
      $.magnificPopup.open({
        items: {
          src: "#order-popup",
        },
        type: "inline",
      });
      return false;
    }

    if (actionFlg === "initial") {
      $("#proDtIndex-" + IndexFlg)
        .find(".smiple_product_lk")
        .hide();
      $("#proDtIndex-" + IndexFlg)
        .find(".addcart_done_maindiv")
        .show();
      return false;
    } else {
      showLoader("proDtIndex-" + IndexFlg, "Idtext");
      var availabilityId = cookie.load("defaultAvilablityId");
      /*var availabilityId = deliveryId;*/
      var availabilityName =
        availabilityId === deliveryId ? "Delivery" : "Pickup";
      var isAddonProduct = "No";
      var productId = productDetail.product_id;
      var customerId =
        typeof cookie.load("UserId") === "undefined"
          ? ""
          : cookie.load("UserId");
      var proqtyQty = $("#proDtIndex-" + IndexFlg)
        .find(".proqty_input")
        .val();
      var specialprice_applicable =
        productDetail.product_specialprice_applicable;
      var postObject = {};
      postObject = {
        app_id: appId,
        product_id: productId,
        product_qty: proqtyQty,
        product_type: 1,
        availability_id: availabilityId,
        availability_name: availabilityName,
        isAddonProduct: isAddonProduct,
        specialprice_applicable: specialprice_applicable,
        reference_id: customerId === "" ? getReferenceID() : "",
        customer_id: customerId,
      };

      Axios.post(
        apiUrlV2 + "cart/simpleCartInsert",
        qs.stringify(postObject)
      ).then((res) => {
        hideLoader("proDtIndex-" + IndexFlg, "Idtext");
        /*  $("#proDtIndex-" + IndexFlg)
          .find(".addcart_done_maindiv")
          .hide();
        $("#proDtIndex-" + IndexFlg)
          .find(".smiple_product_lk")
          .show(); */
        if (res.data.status === "ok") {
          //  this.props.sateValChange("cartflg", "yes");
          showCustomAlert("success", "Great choice! Item added to your cart.");
          this.setState({ cartTriggerFlg: "yes", varSimpleProQty: 1 });
          removePromoCkValue();
          this.handleShowAlertFun(
            "Success",
            "Great choice! Item added to your cart."
          );
          if (appId === "93EDF976-7242-4864-B57E-7B49306521AC") {
            createScriptForAddToCart();
          }
          /*showCartLst();*/
        } else if (res.data.status === "error") {
          var errMsgtxt =
            res.data.message !== ""
              ? res.data.message
              : "Sorry! Products can`t add your cart.";
          // showCustomAlert("error", errMsgtxt);
          this.handleShowAlertFun("Error", errMsgtxt);
          this.handleShowAlertFun("Error", errMsgtxt);
        }

        return false;
      });
    }
  }

  handleShowAlertFun(header, msg) {
    var magnfPopup = $.magnificPopup.instance;
    showAlert(header, msg, magnfPopup);
    $.magnificPopup.open({
      items: {
        src: ".alert_popup",
      },
      type: "inline",
    });
  }

  /* show modifier product details */
  modifierProDetails(proDetailArr) {
    var modProductPrice =
      this.state.modProductPrice !== ""
        ? this.state.modProductPrice
        : proDetailArr.product_price;
    var modProductTotalPrice =
      this.state.modProductTotalPrice !== ""
        ? this.state.modProductTotalPrice
        : proDetailArr.product_price;
    return (
      <div>
        <div className="prd_chosen_row mdf_common_rows">
          <div id="modErrorDiv" className="modfr-alert-danger single-danger">
            {" "}
            Product combination is wrong. Please check your combination{" "}
          </div>

          <div className="product_chosen_inner">
            <input
              type="hidden"
              id="modProductSlug"
              name="modProductSlug"
              value={proDetailArr.product_slug}
            />
            <input
              type="hidden"
              id="modProductId"
              name="modProductId"
              value={proDetailArr.product_id}
            />
            <input
              type="hidden"
              id="modProductPrice"
              name="modProductPrice"
              value={modProductPrice}
            />
            <input
              type="hidden"
              id="modParentProductId"
              name="modParentProductId"
              value={this.state.modParentProductId}
            />
            <input
              type="hidden"
              id="modProductTotalPrice"
              name="modProductTotalPrice"
              value={modProductTotalPrice}
            />

            <div className="product_chosen_col">
              <div className="product_chosen_col_inner">
                {this.modifierProOptions(proDetailArr)}
              </div>
            </div>

            {/*<div className="product_chosen_col product_chosen_col_right">
              <div className="text-box">
                <textarea
                  placeholder="You can enter your special remark in the section..."
                  name="special_notes"
                  id="special_notes"
                  maxLength="30"
                  value={this.state.product_remarks}
                  onChange={this.updateRemarks.bind(this)}
                ></textarea>
                <em>{this.state.remaining} Characters remaining</em>
              </div>
            </div>*/}
          </div>
        </div>

        <div className="prd_chosen_sub_row">
          <p className="total_price product_details_price">
            <sup>{currencySybmol}</sup>
            <span id="id_price_final">{modProductTotalPrice}</span>
          </p>
          <div className="prd_chosen_sub_col popup_addcart_cls modfir_addcart_cls">
            <div
              className="addcart_row prd_chosen_sub_item_left cart_update_div mdf_update_div"
              /* style={{ display: "none" }} */
            >
              <p className="sel-quality">Select Quantity</p>
              <div className="qty_bx">
                <span
                  className="qty_minus"
                  onClick={this.mfdrQtyAction.bind(this, "decr")}
                >
                  -
                </span>
                <input
                  type="text"
                  value={this.state.mdfinput_value}
                  className="modfir_proqty_input"
                  readOnly="1"
                />
                <span
                  className="qty_plus"
                  onClick={this.mfdrQtyAction.bind(this, "incr")}
                >
                  +
                </span>
              </div>
            </div>

            <div
              className="prd_chosen_sub_item_right cart_update_div mdf_update_div"
              /* style={{ display: "none" }} */
            >
              <button
                onClick={this.addToCartModifier.bind(
                  this,
                  proDetailArr,
                  "done"
                )}
              >
                Order Now
              </button>
            </div>

            <div
              className="prd_chosen_sub_item_right prd_chosen_item_full cart_add_div mdf_add_div"
              style={{ display: "none" }}
            >
              <button
                onClick={this.addToCartModifier.bind(
                  this,
                  proDetailArr,
                  "initial"
                )}
              >
                Order Now
              </button>
            </div>

            {/* <div className="cart-success-msg alert alert_success mdfcart_success_msg"></div>
            <div className="cart-error-msg alert alert_danger mdfcart_error_msg"></div> */}
          </div>
        </div>
      </div>
    );
  }

  /* show modifier product option */
  modifierProOptions(mdfproducts) {
    var modifiersArr =
      mdfproducts.modifiers !== null && mdfproducts.modifiers !== ""
        ? mdfproducts.modifiers
        : Array();

    if (modifiersArr.length > 0) {
      const html = modifiersArr.map((item, index) => (
        <div
          className="product_chosen_item_left product_chosen_item_left_full"
          key={index}
        >
          <div className="product_chosen_addons">
            <div className="product_chosen_hea">
              <h6>{stripslashes(item.pro_modifier_name)}</h6>
              <span></span>
            </div>
          </div>

          <div className="form-group custom-select-bxcls">
            <div className={"re_select re_select_" + index}>
              {this.modifierValuesOpn(item)}
            </div>
          </div>
        </div>
      ));

      return html;
    } else {
      return "";
    }
  }

  modifierValuesOpn(mdfMainArr) {
    var mdfrValueArr =
      mdfMainArr.modifiers_values !== null && mdfMainArr.modifiers_values !== ""
        ? mdfMainArr.modifiers_values
        : Array();
    if (mdfrValueArr.length > 0) {
      var mainMdfId = mdfMainArr.pro_modifier_id;
      var mainMdfNm = stripslashes(mdfMainArr.pro_modifier_name);
      var mdfrSelectBoxHtml = "";
      var defaultMdfVal = "";
      $.each(mdfrValueArr, function (index, item) {
        var datamdfVl =
          mainMdfId +
          "~" +
          mainMdfNm +
          "~" +
          stripslashes(item.pro_modifier_value_name) +
          "~" +
          item.pro_modifier_value_id +
          "~" +
          item.pro_modifier_value_price;

        var incPrc =
          parseFloat(item.pro_modifier_value_price) > 0
            ? " (+ " +currencySybmol+ item.pro_modifier_value_price + " )"
            : "";

        mdfrSelectBoxHtml +=
          "<option value='" +
          item.pro_modifier_value_id +
          "' data-mdfvl='" +
          datamdfVl +
          "'>" +
          item.pro_modifier_value_name +
          incPrc +
          "</option>";
        if (item.pro_modifier_value_is_default === "Yes") {
          defaultMdfVal = item.pro_modifier_value_id;
        }
      });
      var mdfrSelectDropDown = Parser(mdfrSelectBoxHtml);
      var statMdfVal = this.state["modifier~~" + mainMdfId];
      var mdfSelectVl =
        statMdfVal !== undefined && statMdfVal !== ""
          ? statMdfVal
          : defaultMdfVal;

      return (
        <select
          name="modifier_main"
          value={mdfSelectVl}
          className="modifierList"
          onChange={this.handleChange.bind(this, mainMdfId)}
          id={"modif" + mainMdfId}
        >
          {mdfrSelectDropDown}
        </select>
      );
    } else {
      return "";
    }
  }

  handleChange(mdfVl, event) {
    $("#modErrorDiv").hide();
    this.setState({
      mdfApi_call: "Yes",
      ["modifier~~" + mdfVl]: event.target.value,
    });
  }

  mfdrQtyAction(actionFlg) {
    var proqtyInput = $(".modfir_proqty_input").val();
    var modProductPrice = $("#modProductPrice").val();
    proqtyInput = parseInt(proqtyInput);
    if (actionFlg === "decr") {
      proqtyInput = proqtyInput > 1 ? proqtyInput - 1 : proqtyInput;
    } else {
      proqtyInput = proqtyInput + 1;
    }

    var productTotalPrice =
      parseFloat(modProductPrice) * parseFloat(proqtyInput);
    productTotalPrice = parseFloat(productTotalPrice).toFixed(2);

    /*$('.modfir_proqty_input').val(proqtyInput);*/
    this.setState({
      mdfApi_call: "No",
      mdfinput_value: proqtyInput,
      modProductTotalPrice: productTotalPrice,
    });
  }

  updateRemarks(event) {
    this.setState({ mdfApi_call: "No", product_remarks: event.target.value });
    this.setState({ remaining: 30 - event.target.value.length });
  }

  addToCartModifier(mdfProdDetail, actionFlg) {
    var avilablityIdTxt =
      cookie.load("defaultAvilablityId") !== "" &&
      typeof cookie.load("defaultAvilablityId") !== undefined &&
      typeof cookie.load("defaultAvilablityId") !== "undefined"
        ? cookie.load("defaultAvilablityId")
        : "";
    if (avilablityIdTxt === "") {
      $.magnificPopup.open({
        items: {
          src: "#order-popup",
        },
        type: "inline",
      });
      return false;
    }

    if (this.state.orderOutletId === "") {
      $.magnificPopup.open({
        items: {
          src: "#order-popup",
        },
        type: "inline",
      });
      return false;
    }
    if (actionFlg === "initial") {
      $(".mdf_add_div").hide();
      $(".mdf_update_div").show();
      return false;
    } else {
      var modCount = $(".modifierList").length;
      $("#modErrorDiv").hide();
      var modifier = [];
      if (modCount > 0) {
        var errorChk = 0;
        $(".modifierList").each(function () {
          var modVal = $(this).val();
          var modSelectVal = $(this).find("option:selected").attr("data-mdfvl");
          if (modVal != "" && modSelectVal != "") {
            var modifier_sets = modSelectVal.split("~");
            var modifierVal = [];
            modifierVal.push({
              modifier_value_id: modifier_sets[3],
              modifier_value_qty: "1",
              modifier_value_name: modifier_sets[2],
              modifier_value_price: modifier_sets[4],
            });
            modifier.push({
              modifier_id: modifier_sets[0],
              modifier_name: modifier_sets[1],
              modifiers_values: modifierVal,
            });
          } else if (modSelectVal == "" || modVal == "") {
            errorChk = 1;
          }
        });

        if (errorChk == 1) {
          $(".mdfcart_error_msg").html("Sorry!. Product Detail was not valid.");
          $(".mdfcart_error_msg").show();
          $(".mdfcart_error_msg").delay(6000).fadeOut();
          return false;
        } else if (errorChk == 0) {
          showLoader("modfir_addcart_cls", "class");

          var availabilityId = cookie.load("defaultAvilablityId");
          var customerId =
            typeof cookie.load("UserId") === "undefined"
              ? ""
              : cookie.load("UserId");
          /*var availabilityId = deliveryId;*/
          var availabilityName =
            availabilityId === deliveryId ? "Delivery" : "Pickup";

          var productRemarks = this.state.product_remarks;
          var prodcutQty = this.state.mdfinput_value;
          var modParentId = this.state.modParentProductId;
          var modProductId = $("#modProductId").val();

          var postObject = {};
          postObject = {
            app_id: appId,
            product_id: modProductId,
            product_modifier_parent_id: modParentId,
            availability_id: availabilityId,
            availability_name: availabilityName,
            product_qty: prodcutQty,
            product_type: 4,
            modifiers: JSON.stringify(modifier),
            menu_set_component: "",
            product_remarks: productRemarks,
            reference_id: customerId === "" ? getReferenceID() : "",
            customer_id: customerId,
          };

          Axios.post(
            apiUrlV2 + "cart/simpleCartInsert",
            qs.stringify(postObject)
          ).then((res) => {
            hideLoader("modfir_addcart_cls", "class");
            if (res.data.status === "ok") {
              /*  $(".mdf_update_div").hide();
              $(".mdf_add_div").show(); */
              showCustomAlert(
                "success",
                "Great choice! Item added to your cart."
              );
              /*  $(".mdfcart_success_msg").html(
                "Successfully! Item added to your cart."
              );
              $(".mdfcart_success_msg").show();
              $(".mdfcart_success_msg").delay(6000).fadeOut(); */
              //  this.props.sateValChange("cartflg", "yes");
              this.setState({ cartTriggerFlg: "yes" });
              /*showCartLst();*/
              removePromoCkValue();
              this.handleShowAlertFun(
                "Success",
                "Great choice! Item added to your cart."
              );
              if (appId === "93EDF976-7242-4864-B57E-7B49306521AC") {
                createScriptForAddToCart();
              }
              return false;
            } else if (res.data.status === "error") {
              var errMsgtxt =
                res.data.message !== ""
                  ? res.data.message
                  : "Sorry!. Product Detail was not valid.";
              this.handleShowAlertFun("Error", errMsgtxt);
              /*  $(".mdfcart_error_msg").html(errMsgtxt);
              $(".mdfcart_error_msg").show();
              $(".mdfcart_error_msg").delay(6000).fadeOut(); */
            }
          });
        }
      } else {
        $(".mdfcart_error_msg").html("Sorry!. Product Detail was not valid.");
        $(".mdfcart_error_msg").show();
        $(".mdfcart_error_msg").delay(6000).fadeOut();
        return false;
      }
    }
  }

  /* show combo product Details */
  comboProDetails(proDetailArr) {
    var comboArr =
      proDetailArr.set_menu_component !== null &&
      proDetailArr.set_menu_component !== ""
        ? proDetailArr.set_menu_component
        : Array();
    if (Object.keys(comboArr).length > 0) {
      var minMaxSelect = parseInt(proDetailArr.product_apply_minmax_select);
      var compoTotalPrice =
        proDetailArr.product_specialprice_applicable === "yes"
          ? proDetailArr.product_special_price
          : proDetailArr.product_price;
      var stepviewSetup = proDetailArr.product_apply_stepview_setup;
        stepviewSetup = (stepviewSetup !== '') ? parseInt(stepviewSetup) : 0;
          
      return (
        <div>
          <div className="prd_chosen_row compo_top_div">
            <div className="product_chosen_inner">
              <input
                type="hidden"
                id="set_menu_component_type"
                name="set_menu_component_type"
                value={minMaxSelect}
              />
              <input
                type="hidden"
                id="product_unitprice"
                name="product_unitprice"
                value={compoTotalPrice}
              />
              <input
                type="hidden"
                id="incr_compo_price"
                name="incr_compo_price"
                value={this.state.incrCompoPrice}
              />

              <div className="product_chosen_col common_compo_div" data-stepview={stepviewSetup}>
                {minMaxSelect === 0 && this.singleComboDet(comboArr)}
                {minMaxSelect === 1 && this.multipleComboDet(comboArr)}
              </div>

              {/*<div className="product_chosen_col product_chosen_col_right">
                <div className="text-box">
                  <textarea
                    placeholder="You can enter your special remark in the section..."
                    name="special_notes"
                    id="special_notes"
                    maxLength="30"
                    value={this.state.product_remarks}
                    onChange={this.updateRemarksCompo.bind(this)}
                  ></textarea>
                  <em>{this.state.remaining} Characters remaining</em>
                </div>
              </div>*/}
            </div>
          </div>

          <div className="prd_chosen_sub_row">
            <p className="total_price product_details_price">
              <div className="product-price">
                {proDetailArr.product_specialprice_applicable === "yes"
                  && ( parseFloat(proDetailArr.product_price) > 0 ? (
                      <span className="price_disc">
                        {showPriceValue(proDetailArr.product_price, "Y")}
                      </span>
                    )
                  : parseFloat(proDetailArr.product_cost) > 0 && (
                      <span className="price_disc">
                        {showPriceValue(proDetailArr.product_cost, "Y")}
                      </span>
                    ))}
              </div>
              <span id="compoprice_final">
                {showPriceValue(compoTotalPrice)}
              </span>
            </p>

            <div className="prd_chosen_sub_col popup_addcart_cls compo_addcart_cls">
              {/* style={{ display: "none" }}  */}

              <div className="addcart_row prd_chosen_sub_item_left cart_update_div compo_update_div">
                <p className="sel-quality">Select Quantity</p>
                <div className="qty_bx">
                  <span
                    className="qty_minus"
                    onClick={this.compoQtyAction.bind(this, "decr")}
                  >
                    -
                  </span>
                  <input
                    type="text"
                    value={this.state.compoinput_value}
                    className="compo_proqty_input"
                    readOnly="1"
                  />
                  <span
                    className="qty_plus"
                    onClick={this.compoQtyAction.bind(this, "incr")}
                  >
                    +
                  </span>
                </div>
              </div>

              <div className="prd_chosen_sub_item_right cart_update_div compo_update_div">
                <button
                  onClick={this.addToCartCombo.bind(this, proDetailArr, "done")}
                >
                 Add to cart
                </button>
              </div>
              {/* <div className="cart-success-msg alert alert_success compocart_success_msg"></div>
              <div className="cart-error-msg alert alert_danger compocart_error_msg"></div> */}
            </div>
          </div>
        </div>
      );
    } else {
      return "";
    }
  }

    singleComboDet(compoProDetailArr) {
    
    if (Object.keys(compoProDetailArr).length > 0) {
      const html = compoProDetailArr.map((item, index1) =>
            <div className={"product_chosen_item_left product_chosen_item_left_full compo_list_div main_combo_div compo_acc_active maincombo-"+item.menu_component_id} data-maincomboidtxt={item.menu_component_id} data-combodata={item.menu_component_id+'~'+item.menu_component_name+'~0'} data-combopriceapply={(item.menu_component_apply_price !== '') ? item.menu_component_apply_price : 0} key={'compo'+index1}>
            <div className="product_chosen_addons compo_pro_acc compo_acc_action">
              <div className="product_chosen_hea compopro_acc_head">
                <h6>{stripslashes(item.menu_component_name)}</h6>
              </div>
            </div>
      
                  <div className="compo_acc_innerdiv">
              <div className="form-group custom-select-bxcls">
               {this.showSingleComboOptions(item)}
              </div>
              
              <div className="mdfr_list_divlcs" data-mismatchpro='' data-invcomboprice=''>
               <div className="mdfr_list_divlcs_error" style={{display:'none',color:'red'}}>Please choose valid modifiers</div> 
               {this.showSingleComboMdfr(item)}
              </div>
            </div>
            
          </div>);
          
      return (<div className="product_chosen_col_inner compo_inner_main">{html}</div>);   
    } else {
      return '';
    }   
  }

  /* show single combo product option */
  showSingleComboOptions(splProducts) {
    var compoListArr = (splProducts.product_details !== null && splProducts.product_details !== '') ? splProducts.product_details : Array();
    var menuComponentApplyPrice = (splProducts.menu_component_apply_price !== '') ? splProducts.menu_component_apply_price : 0;
    if (compoListArr.length > 0) {
        var defaultSelectVl = splProducts.menu_component_default_select;
        var menuSetCmpId = splProducts.menu_set_component_id;
        var menuCmpId = splProducts.menu_component_id;
        var menuComponentNm = stripslashes(splProducts.menu_component_name);
      var compSelectBoxHtml = '';
      var mdfMainComboProId = '';
      var ismdfrProCount = 0;
      var comboPropriceAply = '';
      var comboProPrice = 0;
      var indvlComponentNm = '';
      var showPricetxt = '';
      var compomainselval = '';

      
      var statMdfVal = this.state["compo~~" + menuCmpId];
      var mdfSelectVl = (statMdfVal !== undefined && statMdfVal !== '') ? statMdfVal : defaultSelectVl;
      
      compSelectBoxHtml = compoListArr.map((item1, index2) => {
        
        mdfMainComboProId = item1[0].product_id;
        ismdfrProCount = (item1[0].modifiers !== null && item1[0].modifiers !== '') ? Object.keys(item1[0].modifiers).length : 0;
        comboProPrice = item1[0].product_price;
        comboPropriceAply = (parseFloat(comboProPrice) > 0 && parseInt(menuComponentApplyPrice)===1 && parseInt(ismdfrProCount) === 0) ? comboProPrice : 0;
        indvlComponentNm = (item1[0].product_alias !== '') ? stripslashes(item1[0].product_alias) : stripslashes(item1[0].product_name);
        
        showPricetxt = (parseFloat(comboPropriceAply) > 0) ? " ( + $"+parseFloat(comboPropriceAply).toFixed(2)+" )" : "";
        
        compomainselval = item1[0].product_id+'~'+indvlComponentNm+'~'+item1[0].product_sku+'~'+comboProPrice;
        
        return (<div className='custom_radio' key={"cmbrido-"+index2}><input type='checkbox' name={'combointprochk_'+menuCmpId} value={item1[0].product_id} className='addon_list_single combointprochk_cls showSingleComboOptions subscribe_accept' data-compomainselval={compomainselval} data-mdfcombopro={mdfMainComboProId} data-combopropriceaply={comboPropriceAply} data-ismdfrprochk={ismdfrProCount} checked={this.CheckDefltComboPro(item1[0].product_id,mdfSelectVl)} onClick={this.handleChangeCompoMain.bind(this,menuCmpId)} /><span>{indvlComponentNm+showPricetxt}</span></div>);

        
        
      });
      
    
      return (<div className="components_selct components_selctbox_cls" id={"cmpp"+menuCmpId} >
          {compSelectBoxHtml}
          </div>);
      
    } else {
      return '';
    }
  }


  CheckDefltComboPro(proId,dfltPro) {
    var chkRadioBtn = (proId===dfltPro)?true:false;
    return chkRadioBtn;
  }

  showSingleComboMdfr(splProducts) {
    var compoListArr =
      splProducts.product_details !== null && splProducts.product_details !== ""
        ? splProducts.product_details
        : Array();
    if (compoListArr.length > 0) {
      var defaultSelectVl = splProducts.menu_component_default_select;
      var menuSetCmpId = splProducts.menu_set_component_id;
      var menuCmpId = splProducts.menu_component_id;
      var menuComponentNm = stripslashes(splProducts.menu_component_name);

      const html = compoListArr.map((item1, index2) => (
        <div className="mdfr_list_divlcs_inv" key={"cmdf-" + index2}>
          {item1[0].modifiers !== null &&
            item1[0].modifiers !== "" &&
            this.singleComboMdfrOption(
              menuCmpId,
              item1[0].product_id,
              item1[0].modifiers
            )}
        </div>
      ));

      return html;
    } else {
      return "";
    }
  }

  singleComboMdfrOption(menuCmpIdTxt, productIdTxt, cmpProMdfr) {
    if (Object.keys(cmpProMdfr).length > 0) {
      const html = cmpProMdfr.map((item2, index3) => (
        <div
          className={
            "compoMdfr_item_left individual_combo_mdf compo_mdf_" + productIdTxt
          }
          key={"cmd-" + index3}
        >
          <div className="product_chosen_addons">
            <div className="product_chosen_hea">
              <h6>{stripslashes(item2.pro_modifier_name)}</h6>
            </div>
          </div>

          <div className="form-group custom-select-bxcls">
            <div className={"re_select re_select_cmd" + index3}>
              {this.singleComboMdfrValuesOpn(menuCmpIdTxt, item2)}
            </div>
          </div>
        </div>
      ));

      return html;
    } else {
      return "";
    }
  }

  singleComboMdfrValuesOpn(menuCmpIdTxt, mdfMainArr) {
    var mdfrValueArr =
      mdfMainArr.modifiers_values !== null && mdfMainArr.modifiers_values !== ""
        ? mdfMainArr.modifiers_values
        : Array();
    if (mdfrValueArr.length > 0) {
      var mainMdfId = mdfMainArr.pro_modifier_id;
      var mainMdfNm = stripslashes(mdfMainArr.pro_modifier_name);
      var mdfrSelectBoxHtml = "";
      var defaultMdfVal = "";
      $.each(mdfrValueArr, function (index4, item3) {
        var datamdfVl =
          mainMdfId +
          "~" +
          mainMdfNm +
          "~" +
          item3.pro_modifier_value_id +
          "~" +
          stripslashes(item3.pro_modifier_value_name) +
          "~" +
          item3.pro_modifier_value_price;

        var incPrc =
          parseFloat(item3.pro_modifier_value_price) > 0
            ? " (+ " +currencySybmol+ item3.pro_modifier_value_price + " )"
            : "";

        mdfrSelectBoxHtml +=
          "<option value='" +
          item3.pro_modifier_value_id +
          "' data-selectmdfval='" +
          datamdfVl +
          "' data-mdfrpricevaluetxt='" +
          item3.pro_modifier_value_price +
          "' >" +
          item3.pro_modifier_value_name +
          incPrc +
          "</option>";
        if (item3.pro_modifier_value_is_default === "Yes") {
          defaultMdfVal = item3.pro_modifier_value_id;
        }
      });
      var mdfrSelectDropDown = Parser(mdfrSelectBoxHtml);
      var statMdfVal =
        this.state["compoInner~~" + menuCmpIdTxt + "~~" + mainMdfId];
      var mdfSelectVl =
        statMdfVal !== undefined && statMdfVal !== ""
          ? statMdfVal
          : defaultMdfVal;

      return (
        <select
          name="compoinner_select"
          value={mdfSelectVl}
          className="components_mdf_selct"
          onChange={this.handleChangeCompoInner.bind(
            this,
            menuCmpIdTxt,
            mainMdfId
          )}
          id={"modifvl" + mainMdfId}
        >
          {mdfrSelectDropDown}
        </select>
      );
    } else {
      return "";
    }
  }

  multipleComboDet(compoProDetailArr) {
    
    if (Object.keys(compoProDetailArr).length > 0) {
      const html = compoProDetailArr.map((item, index1) =>
            <div className={"product_chosen_item_left product_chosen_item_left_full compo_list_div main_combo_div minmax_maincombo_div compo_acc_active maincombo-"+item.menu_component_id} data-maincomboidtxt={item.menu_component_id} data-combodata={item.menu_component_id+'~'+item.menu_component_name+'~1'} data-combopriceapply={(item.menu_component_apply_price !== '') ? item.menu_component_apply_price : 0} data-indexcb={index1} data-minselectcombo={item.menu_component_min_select} data-maxselectcombo={item.menu_component_max_select} data-modifierapply={item.menu_component_modifier_apply} key={'compo'+index1}>
            <div className="product_chosen_addons compo_pro_acc compo_acc_action">
              <div className="product_chosen_hea compopro_acc_head">
                <h6>{stripslashes(item.menu_component_name)}</h6>
              </div>
            </div>
      
            <div className="compo_acc_innerdiv">
            
            {(item.menu_component_modifier_apply === '1')?<div className="compo_mdfselect_maindiv">
              <div className="error_combo_div"> Please select the min number of items. </div> 
              <div className="form-group custom-radio-btncls">
               {this.showSingleComboOptions(item)}
              </div>
              
              <div className="mdfr_list_divlcs" data-mismatchpro='' data-invcomboprice=''>
               <div className="mdfr_list_divlcs_error" style={{display:'none',color:'red'}}>Please choose valid modifiers</div> 
               {this.showSingleComboMdfr(item)}
              </div>
            
                    </div>:<div className="compo_minmax_maindiv">
              
                <div className="max-min-bar">{"You've chosen"} <span className="minSelectCls">0</span>  (Min. {item.menu_component_min_select} & Max. {item.menu_component_max_select}) </div>
              
              <div className="error_combo_div"> Please select the min number of items. </div>
              
              {this.showMultipleComboOptions(item)}
            </div>}
            
            </div>
            
          </div>);
          
      return (<div className="product_chosen_col_inner compo_inner_main">{html}</div>);   
    } else {
      return '';
    }
  }

  showComboMultiSelect(mutilSlct) {
    return mutilSlct === 1 ? "none" : "";
  }

  /* show multiple combo product option */
  showMultipleComboOptions(splProducts) {
    var multiSelectApply =
      splProducts.menu_component_multipleselection_apply !== ""
        ? parseInt(splProducts.menu_component_multipleselection_apply)
        : 0;
    var compoListArr =
      splProducts.product_details !== null && splProducts.product_details !== ""
        ? splProducts.product_details
        : Array();
    var menuComponentApplyPrice =
      splProducts.menu_component_apply_price !== ""
        ? splProducts.menu_component_apply_price
        : 0;
    if (compoListArr.length > 0) {
      var defaultSelectVl = splProducts.menu_component_default_select;
      var menuSetCmpId = splProducts.menu_set_component_id;
      var menuCmpId = splProducts.menu_component_id;

      const compoMtplHtml = compoListArr.map((item1, index1) => (
        <div
          className={
            "chosen_adn mdfr_list_divlcs individual_combo_pro indvcombo-" +
            menuCmpId +
            "-" +
            item1[0].product_primary_id
          }
          data-mismatchpro=""
          data-invcomboprice="0"
          data-comboprice={item1[0].product_price}
          data-productdata={this.getCmpProData(item1)}
          key={index1}
        >
          <div className="bb-txt2 margin-15 chosen_adn_left">
            <span>
              {item1[0].product_alias !== ""
                ? stripslashes(item1[0].product_alias)
                : stripslashes(item1[0].product_name)}
            </span>{" "}
            <span className="combo_pro_price" style={{ display: "none" }}>
              {parseFloat(item1[0].product_price) > 0
                ? " ( + "+currencySybmol + item1[0].product_price + " )"
                : ""}
            </span>
          </div>
          <div
            className="pull-right combo-inc-parent chosen_adn_right"
            style={{ display: this.showComboMultiSelect(multiSelectApply) }}
          >
            <div className="qty_bx">
              <span
                className="qty_minus combo_inc"
                onClick={this.decComboQty.bind(
                  this,
                  menuCmpId,
                  item1[0].product_primary_id
                )}
              >
                -
              </span>

              <label
                type="text"
                disabled
                data-qtyval="0"
                className="combo-input-label combo-input combolst_qty_value"
              >
                0
              </label>

              <span
                className="qty_plus combo_dec"
                onClick={this.incComboQty.bind(
                  this,
                  menuCmpId,
                  item1[0].product_primary_id
                )}
              >
                +
              </span>
            </div>
          </div>

          {multiSelectApply === 1 && (
            <div className="checkboxcls pull-right">
              <input
                className="css-checkboxcls"
                type="checkbox"
                onChange={this.comboMultiSelectUpdate.bind(
                  this,
                  menuCmpId,
                  item1[0].product_primary_id
                )}
                value={item1[0].product_price}
                name={"comboMultiStVal_" + menuCmpId}
                id={
                  "comboMultiStVal_" +
                  menuCmpId +
                  "_" +
                  item1[0].product_primary_id
                }
              />{" "}
              <label
                htmlFor={
                  "comboMultiStVal_" +
                  menuCmpId +
                  "_" +
                  item1[0].product_primary_id
                }
                className="css-label-chkbox"
              ></label>
            </div>
          )}
        </div>
      ));

      return <div>{compoMtplHtml}</div>;
    } else {
      return "";
    }
  }

  getCmpProData(proData) {
    var proName =
      proData[0].product_alias !== ""
        ? stripslashes(proData[0].product_alias)
        : stripslashes(proData[0].product_name);
    var pro_datetxt =
      proData[0].product_id +
      "~" +
      proName +
      "~" +
      proData[0].product_sku +
      "~" +
      proData[0].product_price;
    return pro_datetxt;
  }

  comboMultiSelectUpdate(menuCmpId, productPryId) {
    if (
      $("#comboMultiStVal_" + menuCmpId + "_" + productPryId).prop("checked") ==
      true
    ) {
      this.incComboQty(menuCmpId, productPryId, "checkboxact");
    } else {
      this.decComboQty(menuCmpId, productPryId);
    }
  }

  incComboQty(menuCmpId, proId, actionFrm) {
    var $_this = $(".indvcombo-" + menuCmpId + "-" + proId);
    var intValInc = $_this.find(".combolst_qty_value").attr("data-qtyval");
    intValInc = intValInc !== "" ? parseInt(intValInc) : 0;
    var minselectcombo =
      $_this.closest(".main_combo_div").attr("data-minselectcombo") != ""
        ? $_this.closest(".main_combo_div").attr("data-minselectcombo")
        : "0";
    var maxselectcombo =
      $_this.closest(".main_combo_div").attr("data-maxselectcombo") != ""
        ? $_this.closest(".main_combo_div").attr("data-maxselectcombo")
        : "0";
    var combopriceapply =
      $_this.closest(".main_combo_div").attr("data-combopriceapply") != ""
        ? $_this.closest(".main_combo_div").attr("data-combopriceapply")
        : "0";
    var invCompoQty = this.getInvCompoQty($_this);

    if (
      actionFrm === "checkboxact" &&
      parseInt(invCompoQty) >= parseInt(maxselectcombo)
    ) {
      $("#comboMultiStVal_" + menuCmpId + "_" + proId).prop("checked", false);
      return false;
    }

    var chk_val = 0;
    if (!isNaN(intValInc) && parseInt(invCompoQty) < parseInt(maxselectcombo)) {
      intValInc = parseInt(intValInc + 1);
      chk_val = 1;
    }

    $_this.find(".combolst_qty_value").attr("data-qtyval", intValInc);
    $_this.find(".combolst_qty_value").html(intValInc);

    var comboProPrice = $_this.attr("data-invcomboprice");
    var invCompoQty = this.getInvCompoQty($_this);
    if (
      (invCompoQty > parseInt(minselectcombo) ||
        parseInt(combopriceapply) == 1) &&
      chk_val == 1
    ) {
      $_this.find(".combo_pro_price").show();
      var invComboproPrice = $_this.attr("data-invcomboprice");
      var comboprice = $_this.attr("data-comboprice");
      comboProPrice = parseFloat(invComboproPrice) + parseFloat(comboprice);
    }

    if (parseInt(minselectcombo) == 0 || parseInt(combopriceapply) == 1) {
      $_this.find(".combo_pro_price").show();
    }
    $_this.attr("data-invcomboprice", comboProPrice);

    this.updateProductPricefun();
  }

  decComboQty(menuCmpId, proId) {
    var $_this = $(".indvcombo-" + menuCmpId + "-" + proId);
    var intValInc = $_this.find(".combolst_qty_value").attr("data-qtyval");
    intValInc = intValInc !== "" ? parseInt(intValInc) : 0;
    var minselectcombo =
      $_this.closest(".main_combo_div").attr("data-minselectcombo") != ""
        ? $_this.closest(".main_combo_div").attr("data-minselectcombo")
        : "0";
    var maxselectcombo =
      $_this.closest(".main_combo_div").attr("data-maxselectcombo") != ""
        ? $_this.closest(".main_combo_div").attr("data-maxselectcombo")
        : "0";
    var combopriceapply =
      $_this.closest(".main_combo_div").attr("data-combopriceapply") != ""
        ? $_this.closest(".main_combo_div").attr("data-combopriceapply")
        : "0";

    var minusChkVal = 0;
    if (!isNaN(intValInc) && parseInt(intValInc) >= 1) {
      var intValInc = parseInt(intValInc - 1);
      minusChkVal = 1;
    }

    $_this.find(".combolst_qty_value").attr("data-qtyval", intValInc);
    $_this.find(".combolst_qty_value").html(intValInc);
    var invCompoQty = this.getInvCompoQty($_this);
    var comboProPrice = $_this.attr("data-invcomboprice");
    var defComboprice = $_this.attr("data-comboprice");
    if (
      (invCompoQty >= parseInt(minselectcombo) && minusChkVal == 1) ||
      (parseInt(combopriceapply) == 1 && minusChkVal == 1)
    ) {
      if (
        parseInt(combopriceapply) == 1 &&
        minusChkVal == 1 &&
        parseFloat(defComboprice) == 0 &&
        parseFloat(comboProPrice) == 0
      ) {
        var temp_price = 0;
        $_this.attr("data-invcomboprice", temp_price);
        $_this.find(".combo_pro_price").hide();
      } else if (
        parseFloat(comboProPrice) >= parseFloat(defComboprice) &&
        parseFloat(comboProPrice) > 0
      ) {
        var temp_price = parseFloat(comboProPrice) - parseFloat(defComboprice);
        $_this.attr("data-invcomboprice", temp_price);
        if (parseFloat(temp_price) == 0) {
          $_this.find(".combo_pro_price").hide();
        }
      } else {
        var rtn_val = 0;
        $_this
          .closest(".main_combo_div")
          .find(".individual_combo_pro")
          .each(function () {
            var thisInvPrc = $(this).attr("data-invcomboprice");
            if (parseFloat(thisInvPrc) > 0 && rtn_val == 0) {
              rtn_val = 1;
              var comboproprice = thisInvPrc;
              var def_combo_price = $(this).attr("data-comboprice");
              var tempPrice =
                parseFloat(comboproprice) - parseFloat(def_combo_price);
              $(this).attr("data-invcomboprice", tempPrice);
              if (parseFloat(tempPrice) == 0) {
                $(this).find(".combo_pro_price").hide();
              }
            }
          });
      }
    }

    if (parseInt(minselectcombo) == 0 || parseInt(combopriceapply) == 1) {
      $_this.find(".combo_pro_price").show();
    }

    this.updateProductPricefun();
  }

  getInvCompoQty($_this) {
    $_this.closest(".main_combo_div").find(".error_combo_div").hide();
    var combolst_qty = 0;
    $_this
      .closest(".main_combo_div")
      .find(".combolst_qty_value")
      .each(function () {
        combolst_qty += parseInt($(this).attr("data-qtyval"));
      });
    return combolst_qty;
  }

  updateRemarksCompo(event) {
    this.setState({ compoApi_call: "No", product_remarks: event.target.value });
    this.setState({ remaining: 30 - event.target.value.length });
  }

  updateProductPricefun() {
    var minmaxMainCnt = $(".compo_minmax_maindiv").length;
    if (minmaxMainCnt > 0) {
      $(".compo_minmax_maindiv").each(function (indx) {
        var invQtyCnt = 0;
        $(this)
          .find(".individual_combo_pro")
          .each(function (indx2) {
            var qtyval = $(this)
              .find(".combolst_qty_value")
              .attr("data-qtyval");
            invQtyCnt = parseInt(invQtyCnt) + parseInt(qtyval);
          });
        $(this).find(".minSelectCls").html(invQtyCnt);
      });
    }

    var combo_pro_price = 0;
    $(".mdfr_list_divlcs").each(function () {
      var invcomboPriceVl = $(this).attr("data-invcomboprice");
      invcomboPriceVl = invcomboPriceVl !== "" ? invcomboPriceVl : 0;
      combo_pro_price += parseFloat(invcomboPriceVl);
    });

    var qty_txt =
      $(".compo_proqty_input").val() != ""
        ? parseInt($(".compo_proqty_input").val())
        : 0;
    var pro_price_val =
      $("#product_unitprice").val() != ""
        ? parseFloat($("#product_unitprice").val())
        : 0;

    var exc_price = parseFloat(pro_price_val) + parseFloat(combo_pro_price);
    exc_price = parseInt(qty_txt) * parseFloat(exc_price);

    $("#incr_compo_price").val(exc_price);
    $("#compoprice_final").html(
      showPriceValue(parseFloat(exc_price).toFixed(2))
    );
  }

  compoQtyAction(actionFlg) {
    var proqtyInput = $(".compo_proqty_input").val();
    proqtyInput = parseInt(proqtyInput);
    if (actionFlg === "decr") {
      proqtyInput = proqtyInput > 1 ? proqtyInput - 1 : proqtyInput;
    } else {
      proqtyInput = proqtyInput + 1;
    }

    this.setState(
      { compoApi_call: "No", compoinput_value: proqtyInput },
      function () {
        this.updateProductPricefun();
      }
    );
  }

  handleChangeCompoMain(compoVl, event) {
    this.setState({
      compoApi_call: "Yes",
      ["compo~~" + compoVl]: event.target.value,
    });
  $(event.target).parents('.compo_mdfselect_maindiv').find('.error_combo_div').hide();
  }

  handleChangeCompoInner(menuCmpIdTxt, compoVl, event) {
    this.setState({
      compoApi_call: "Yes",
      ["compoInner~~" + menuCmpIdTxt + "~~" + compoVl]: event.target.value,
    });
  }

  getComboproData($_this,CompoType) {
    var comboproSet = Array(),
      comboproMdf = Array(),
      aplypriceZero = 0;
      
      if(CompoType === 1) {
        
        $_this.find('.individual_combo_pro').each(function()
        {
          var componentsProDet = $(this).attr('data-productdata');
          var compoProDet = componentsProDet.split('~');
          var combolstQtyValue = $(this).find('.combolst_qty_value').attr('data-qtyval');
          var comboProInvPrice = $(this).attr('data-invcomboprice');
          comboproSet.push(
          {
            'product_id': compoProDet[0],
            'product_name': compoProDet[1],
            'product_sku': compoProDet[2],
            'product_price': comboProInvPrice,
            'product_qty': combolstQtyValue,
            'modifiers': comboproMdf
          });
        });
        
      } else {
        $_this.find('.components_selct').each(function()
        {
          /*var mdfcombopro_id = $(this).find(':selected').attr('data-mdfcombopro');*/
          var mdfcombopro_id = $(this).find(':checked').attr('data-mdfcombopro');
          var combopriceapplychk = $(this).closest('.main_combo_div').attr('data-combopriceapply');
          /*var aplyprice_temp = $(this).find(':selected').attr('data-combopropriceaply');*/
          var aplyprice_temp = $(this).find(':checked').attr('data-combopropriceaply');
              aplyprice_temp = (aplyprice_temp !== undefined) ? aplyprice_temp : 0;
          var comboMdfSet = Array();
          $(this).closest('.main_combo_div').find('.compo_mdf_' + mdfcombopro_id).each(function()
          {
            var combopro_mdf_txt = $(this).find('.components_mdf_selct').val();
            var comboMdfValueSet = Array();
            if (combopro_mdf_txt !== '')
            {
              
              var modifierCombosets_txt = $(this).find('.components_mdf_selct').find('option:selected').attr("data-selectmdfval");
              var mdfSetDet = (modifierCombosets_txt !== '' && modifierCombosets_txt !== undefined) ? modifierCombosets_txt.split('~') : Array();
              
              if(Object.keys(mdfSetDet).length > 0) {
                comboMdfValueSet.push(
                {
                  'modifier_value_name': mdfSetDet[3],
                  'modifier_value_id': mdfSetDet[2],
                  'modifier_value_price': mdfSetDet[4],
                  'modifier_value_qty': 1
                });
                comboMdfSet.push(
                {
                  'modifier_name': mdfSetDet[1],
                  'modifier_id': mdfSetDet[0],
                  'modifiers_values': comboMdfValueSet
                });
              }
              
            }
          });
          
          /*var componentsProDet = $(this).find('option:selected').attr("data-compomainselval");*/
          var componentsProDet = $(this).find(':checked').attr("data-compomainselval");
          var compoProDet = (componentsProDet !== undefined) ? componentsProDet.split('~') : Array();
          if(Object.keys(compoProDet).length > 0) {
            var combolstQtyValue = 1;
            var comboProInvPrice = (parseFloat(combopriceapplychk) > 0) ? parseFloat(aplyprice_temp) : parseFloat(aplypriceZero);
            comboproSet.push(
            {
              'product_id': compoProDet[0],
              'product_name': compoProDet[1],
              'product_sku': compoProDet[2],
              'product_price': comboProInvPrice,
              'product_qty': combolstQtyValue,
              'modifiers': comboMdfSet
            });
          }
        });
      }
      
    return comboproSet;   
  }

  checkMinValfun($_this) {
    var combo_qtycount = 0,
      min_val_error = 0;
    var min_selectcombo =
      $_this.attr("data-minselectcombo") != ""
        ? $_this.attr("data-minselectcombo")
        : "0";
    $_this.find(".combolst_qty_value").each(function () {
      var qtyval = $(this).attr("data-qtyval");
      combo_qtycount += parseInt(qtyval);
    });
    if (parseInt(min_selectcombo) > parseInt(combo_qtycount)) {
      min_val_error = 1;
    }
    return min_val_error;
  }

  addToCartCombo(compoProdDetail, actionFlg) {

    var avilablityIdTxt =
      cookie.load("defaultAvilablityId") !== "" &&
      typeof cookie.load("defaultAvilablityId") !== undefined &&
      typeof cookie.load("defaultAvilablityId") !== "undefined"
        ? cookie.load("defaultAvilablityId")
        : "";
    if (avilablityIdTxt === "") {
      $.magnificPopup.open({
        items: {
          src: "#order-popup",
        },
        type: "inline",
      });
      return false;
    }

    if (this.state.orderOutletId === "") {
      $.magnificPopup.open({
        items: {
          src: "#order-popup",
        },
        type: "inline",
      });
      return false;
    }
    
    var InvalidMdfrCompo = 'No';
    $('.main_combo_div').each(function(){
     if($(this).find('.mdfr_list_divlcs').attr('data-mismatchpro') == '1'){
      InvalidMdfrCompo = 'Yes';
     }
    });
    
    if(InvalidMdfrCompo == 'No') {
    
    if (actionFlg === 'initial') {
      $(".compo_add_div").hide();
      $(".compo_update_div").show();
      return false;
    } else {
        var $_react_this = this;
        var menuSet = Array();
        var productDetailsMain = Array();
        var modifier = Array();
        var CompoType = $('#set_menu_component_type').val();
        CompoType = parseInt(CompoType);
      var compo_errors = '0';
            var indexCb = 0;
      if(CompoType === 1) {
        
        $('.main_combo_div').each(function()
        {
          var modifierapply = $(this).attr('data-modifierapply');
            if (modifierapply === '1') {
                        var check_min_val = $_react_this.checkMinValfunForApplyMdf($(this));
                        if (check_min_val == 0) {
                            var combodata_txt = $(this).attr('data-combodata');
                            var menu_component = combodata_txt.split('~');

                            var productDetails = $_react_this.getComboproData($(this), 0);
                            productDetailsMain.push({
                                'menu_component_id': menu_component[0],
                                'menu_component_name': menu_component[1],
                                'product_details': productDetails,
                                'min_max_flag': menu_component[2]
                            });
                        } else {
                            compo_errors = '1';
                            $(this).find('.compo_mdfselect_maindiv:first').find('.error_combo_div:first').show().addClass('active-error');
                            $("html, body").animate(
                              {
                                scrollTop: $(document).find(".error_combo_div").offset().top - 200,
                              },
                              1000
                            );
                            $('.new-alert-info').show();
                            indexCb = $(this).attr('data-indexcb');

                            var stepviewVal = $(this).closest('.common_compo_div').attr('data-stepview');
                            if (stepviewVal !== '1') {
                                $(this).closest('.common_compo_div').find('.main_combo_div').not($(this)).removeClass('compo_acc_active');
                                $(this).closest('.common_compo_div').find('.main_combo_div').find('.compo_acc_innerdiv').not($(this).find('.compo_acc_innerdiv')).hide();
                                $(this).addClass('compo_acc_active');
                                $(this).find('.compo_acc_innerdiv').show();
                            }

                            return false;
                        }

                      } else {
                          var check_min_val = $_react_this.checkMinValfun($(this));
                          if (check_min_val == 0) {
                              var combodata_txt = $(this).attr('data-combodata');
                              menu_component = combodata_txt.split('~');
                              var productDetails = $_react_this.getComboproData($(this), CompoType);
                              productDetailsMain.push({
                                  'menu_component_id': menu_component[0],
                                  'menu_component_name': menu_component[1],
                                  'product_details': productDetails,
                                  'min_max_flag': menu_component[2]
                              });
                          } else {
                              compo_errors = '1';
                              $(this).find('.error_combo_div').show();
                              $("html, body").animate(
                                {
                                  scrollTop: $(document).find(".error_combo_div").offset().top - 200,
                                },
                                1000
                              );
                              return false;
                          }
                      }
                  });
                  if(compo_errors == 1){
                    return false;
                  }
        
      } else {
        
        $('.main_combo_div').each(function()
        {
          var combodata_txt = $(this).attr('data-combodata');
          var menu_component = combodata_txt.split('~');
          var productDetails = $_react_this.getComboproData($(this),CompoType);
          productDetailsMain.push(
          {
            'menu_component_id': menu_component[0],
            'menu_component_name': menu_component[1],
            'product_details': productDetails,
            'min_max_flag': menu_component[2]
          });
        });
        
      }
      menuSet = productDetailsMain;
      
      if(compo_errors == '0' && Object.keys(menuSet).length > 0)
        {
        showLoader('compo_addcart_cls','class');
        var prCommon = this.props.productcommon;
        
        var productId     = compoProdDetail.product_id;
        var productName   = compoProdDetail.product_alias !== '' ? stripslashes(compoProdDetail.product_alias) : stripslashes(compoProdDetail.product_name);
        var productImage  = (compoProdDetail.product_thumbnail !== '')?prCommon.image_source+'/'+compoProdDetail.product_thumbnail:'';
        var productSku    = compoProdDetail.product_sku;
        var productSlug   = compoProdDetail.product_slug;
        
        var productRemarks  = this.state.product_remarks;
        var prodcutQty    = this.state.compoinput_value;
        var incrCompoPrice  = $("#incr_compo_price").val();
        
        var totalCompoPrice  = (incrCompoPrice !== '') ? parseFloat(incrCompoPrice) : 0;
        var unitProductPrice = (parseFloat(totalCompoPrice) / parseFloat(prodcutQty));
          unitProductPrice = unitProductPrice.toFixed(2);
        
        var availabilityId = cookie.load("defaultAvilablityId");
        var customerId = (typeof cookie.load('UserId') === 'undefined') ? '' : cookie.load('UserId');
        
        if(parseFloat(totalCompoPrice)>0 && parseFloat(unitProductPrice)>0) {
        
        var postObject = {};
          postObject = {
            "app_id": appId,
            "product_id": productId,
            "product_qty": prodcutQty,
            "availability_id": availabilityId,
            "product_name": productName,
            "product_total_price": totalCompoPrice,
            "product_unit_price": unitProductPrice,
            "product_remarks": "",
            "product_image": productImage,
            "product_sku": productSku,
            "product_slug": productSlug,
            "modifiers": JSON.stringify(modifier),
            "menu_set_component": JSON.stringify(menuSet),
            "individual": 'yes',
            "customer_id": customerId,
            "reference_id": (customerId === '') ? getReferenceID() : '',
            "product_edit_enable": 'No'
          };
          

          Axios.post(apiUrl + "cart/insert", qs.stringify(postObject)).then(res => {
            hideLoader('compo_addcart_cls','class');
            if (res.data.status === "ok") {
              // $('.compo_update_div').hide();
              $('.compo_add_div').show();
              this.setState({compoinput_value: 1});
              showCustomAlert('success','Great choice! Item added to your cart.');
              $(".compocart_success_msg").html("Successfully! Item added to your cart.");
              $(".compocart_success_msg").show();
              $(".compocart_success_msg").delay(6000).fadeOut();
              this.sateValChange('cartflg', 'yes');
              this.setState({cartTriggerFlg: 'yes'});
              $("#ProductDetailMdl").modal("hide");
              /*showCartLst();*/
              removePromoCkValue();
              this.handleShowAlertFun('Success','Great choice! Item added to your cart.');
              if (appId === "93EDF976-7242-4864-B57E-7B49306521AC") {
                createScriptForAddToCart();
              }
              return false;
            } else if (res.data.status === "error") {
              this.setState({compoinput_value: 1});
              var errMsgtxt = (res.data.message !== '') ? res.data.message : "Product Detail was not valid.";
              $(".compocart_error_msg").html(errMsgtxt);
              $(".compocart_error_msg").show();
              $(".compocart_error_msg").delay(6000).fadeOut();
            }
          });
          
            } else {
              hideLoader('compo_addcart_cls','class');
            $(".compocart_error_msg").html("Sorry!. Product price was not valid.");
            $(".compocart_error_msg").show();
            $(".compocart_error_msg").delay(6000).fadeOut();
            return false;
          }
        
      } else {
        $(".compocart_error_msg").html("Sorry!. Product Detail was not valid.");
        $(".compocart_error_msg").show();
        $(".compocart_error_msg").delay(6000).fadeOut();
        return false;
      }
    }
    
    } else {
      $(".compocart_error_msg").html("Sorry!. Invalid product combination.");
      $(".compocart_error_msg").show();
      $(".compocart_error_msg").delay(6000).fadeOut();
      return false;
    }
  }
  
  checkMinValfunForApplyMdfInnr($_this) {
    var combo_qtycount = 0,
      min_val_error = 0;
    var min_selectcombo = ($_this.attr('data-minselectcombo') != '') ? $_this.attr('data-minselectcombo') : '0';
    $_this.find('.showSingleComboOptionsInnerChkBx').each(function() {
      if($(this).prop('checked')) {
        combo_qtycount = 1;
      }
    });
    if ((parseInt(min_selectcombo) > 0) && (parseInt(combo_qtycount) == 0))
    {
     min_val_error = 1;
    }
    return min_val_error;   
        
  }
  
  checkMinValfunForApplyMdf($_this) {
      var combo_qtycount = 0,
      min_val_error = 0;
    var $_react_this = this;  
    var min_selectcombo = ($_this.attr('data-minselectcombo') != '') ? $_this.attr('data-minselectcombo') : '0';
    $_this.find('.showSingleComboOptions').each(function()
    {
      if($(this).prop('checked')) {

        if($(this).attr('data-iscomborprochk') == 'yes') {
          var dataRand = $(this).attr('data-rand');
          if ($(this).closest('.' + dataRand).find('.main_combo_div').length > 0) {
            $(this).closest('.' + dataRand).find('.main_combo_div').each(function(){
              if($(this).attr('data-isinnercombo') == 'yes') {
                var modifierapply = $(this).attr('data-modifierapply');
                var check_min_val = (modifierapply === '1') ? $_react_this.checkMinValfunForApplyMdfInnr($(this)) : $_react_this.checkMinValfun($(this));
                if (check_min_val == 0)
                {
                  combo_qtycount = 1;
                }
                else
                {
                  min_val_error = 1;
                  combo_qtycount = 0;
                  var prntCls = (modifierapply === '1') ? 'compo_mdfselect_maindiv' : 'compo_minmax_maindiv';
                  $(this).find('.'+prntCls).find('.error_combo_div').show().addClass('active-error');
                  $("html, body").animate(
                    {
                      scrollTop: $(document).find(".error_combo_div").offset().top - 200,
                    },
                    1000
                  );
                  $('.new-alert-info').show();
                  var stepviewVal = $(this).closest('.common_compo_div').attr('data-stepview');
                  if(stepviewVal !== '1') {
                    $(this).closest('.common_compo_div').find('.main_combo_div').not($(this)).removeClass('compo_acc_active');
                    $(this).closest('.common_compo_div').find('.main_combo_div').find('.compo_acc_innerdiv').not($(this).find('.compo_acc_innerdiv')).hide();
                    $(this).addClass('compo_acc_active');
                    $(this).find('.compo_acc_innerdiv').show();
                  }
                  return false;
                }
                }
              
            });
          }
          
        } else {
          combo_qtycount = 1;
        }
      }
    });
    if ((parseInt(min_selectcombo) > 0) && (parseInt(combo_qtycount) == 0))
    {
      min_val_error = 1;
    }
    return min_val_error;
  }


  checkProductPrice() {
    var allModVal = "";
    var errorChk = 0;
    var productID = $("#modProductId").val();
    var inc_lp = 1;
    var TotalCnt = $(".modifierList").length;

    $(".modfir_addcart_cls").show();
    showLoader("modfir_addcart_cls", "class");

    $(".modifierList").each(function () {
      var modVal = $(this).val();
      var modSelectVal = $(this).find("option:selected").attr("data-mdfvl");
      if (modVal != "" && modSelectVal != "") {
        var modifier_sets = modSelectVal.split("~");
        allModVal +=
          inc_lp == TotalCnt ? modifier_sets[3] : modifier_sets[3] + ";";
      } else if (modSelectVal == "" || modVal == "") {
        errorChk = 1;
      }
      inc_lp++;
    });

    if (errorChk === 0 && allModVal != "") {
      Axios.get(
        apiUrl +
          "products/validate_product?app_id=" +
          appId +
          "&product_id=" +
          productID +
          "&modifier_value_id=" +
          allModVal
      ).then((res) => {
        var response = res.data;
        hideLoader("modfir_addcart_cls", "class");
        if (response.status === "ok") {
          var proQty = $(".modfir_proqty_input").val();
          var productPrice = response.result_set[0].product_price;
          var productTotalPrice =
            parseFloat(response.result_set[0].product_price) *
            parseFloat(proQty);
          productTotalPrice = parseFloat(productTotalPrice).toFixed(2);
          /*$("#modParentProductId").val(response.result_set[0].alias_product_primary_id);
          $("#modProductPrice").val(productPrice);
          $("#modProductTotalPrice").val(productTotalPrice);
          $('#id_price_final').html(productTotalPrice);*/
          this.setState({
            mdfApi_call: "No",
            modParentProductId: response.result_set[0].alias_product_primary_id,
            modProductPrice: productPrice,
            modProductTotalPrice: productTotalPrice,
          });
        } else {
          $(".modfir_addcart_cls").hide();
          $("#modErrorDiv").show();
          $("#modErrorDiv").delay(6000).fadeOut();
        }
      });
    }
  }

  setModifierValFun($_this) {
    var mdfcombopro = $_this.find(":checked").attr("data-mdfcombopro");
    $_this.closest(".main_combo_div").find(".individual_combo_mdf").hide();
    $_this
      .closest(".main_combo_div")
      .find(".compo_mdf_" + mdfcombopro)
      .show();
  }

  setOverallSubmdfrPrice($_this) {
    var mainmdfrid = $_this.find(":checked").attr("data-mdfcombopro");
    var ismdfrprochk = $_this.find(":checked").attr("data-ismdfrprochk");
    var combopriceapplychk = $_this
      .closest(".main_combo_div")
      .attr("data-combopriceapply");
    var maincomboidtxt = $_this
      .closest(".main_combo_div")
      .attr("data-maincomboidtxt");
    var mdfrpricevaluetxt_val = 0,
      inv_comopo_mismatch_pro = "";

    if (parseFloat(ismdfrprochk) > 0) {
      if (this.state.compoApi_call === "Yes") {
        var rtrn_msg = this.checkModifierPricefun(
          $_this,
          maincomboidtxt,
          mainmdfrid
        );
      }
    } else {
      if (parseFloat(combopriceapplychk) > 0) {
        var aplyprice_temp = $_this
          .find(":checked")
          .attr("data-combopropriceaply");
          aplyprice_temp = (aplyprice_temp !== undefined) ? aplyprice_temp : 0;
        mdfrpricevaluetxt_val = parseFloat(aplyprice_temp);
      }
      $_this
        .closest(".main_combo_div")
        .find(".mdfr_list_divlcs")
        .attr("data-invcomboprice", mdfrpricevaluetxt_val);
      $_this
        .closest(".main_combo_div")
        .find(".mdfr_list_divlcs")
        .attr("data-mismatchpro", inv_comopo_mismatch_pro);
      $_this.closest(".main_combo_div").find(".mdfr_list_divlcs_error").hide();
    }

    this.checkModifierErrorfun();
  }

  checkModifierPricefun($_this, maincomboidtxt, mdfcombopro_id) {
    var returntxt_msg = "";
    var sub_mdfr_ids = "";
    $(".maincombo-" + maincomboidtxt)
      .find(".compo_mdf_" + mdfcombopro_id)
      .each(function () {
        var modVal = $(this).find(".components_mdf_selct").val();
        if (modVal !== "") {
          var modifier_combosets_txt = $(this)
            .find(".components_mdf_selct")
            .find("option:selected")
            .attr("data-selectmdfval");
          var modifier_combosets =
            modifier_combosets_txt !== "" &&
            modifier_combosets_txt !== undefined
              ? modifier_combosets_txt.split("~")
              : new Array();
          if (modifier_combosets.length >= 2) {
            if (modifier_combosets[2]) {
              if (sub_mdfr_ids != "") {
                sub_mdfr_ids += ";";
              }
              sub_mdfr_ids = sub_mdfr_ids + modifier_combosets[2];
            }
          }
        }
      });

    if (sub_mdfr_ids !== "") {
      showLoader("compo_addcart_cls", "class");
      Axios.get(
        apiUrl +
          "products/validate_product?app_id=" +
          appId +
          "&product_id=" +
          mdfcombopro_id +
          "&modifier_value_id=" +
          sub_mdfr_ids
      ).then((res) => {
        var response = res.data;
        if (response.status === "ok") {
          var tempval = "";
          this.updateIndvModifrprice(
            $_this
              .closest(".main_combo_div")
              .find(".compo_mdf_" + mdfcombopro_id + ":first")
          );
        } else {
          var tempval = "1";
        }
        $_this
          .closest(".main_combo_div")
          .find(".mdfr_list_divlcs")
          .attr("data-mismatchpro", tempval);
        this.checkModifierErrorfun();
        hideLoader("compo_addcart_cls", "class");
      });
    }

    return returntxt_msg;
  }

  updateIndvModifrprice($_this) {
    var mdfrpricevaluetxt_val = 0;
    $_this
      .closest(".mdfr_list_divlcs_inv")
      .find(".components_mdf_selct")
      .each(function () {
        var mdfrpricevaluetxt = $(this)
          .find(":checked")
          .attr("data-mdfrpricevaluetxt");
        mdfrpricevaluetxt =
          mdfrpricevaluetxt !== "" && mdfrpricevaluetxt !== undefined
            ? parseFloat(mdfrpricevaluetxt)
            : 0;
        mdfrpricevaluetxt_val =
          parseFloat(mdfrpricevaluetxt_val) + parseFloat(mdfrpricevaluetxt);
      });
    $_this
      .closest(".mdfr_list_divlcs")
      .attr("data-invcomboprice", mdfrpricevaluetxt_val);
  }

  checkModifierErrorfun() {
    var over_allerror = "";
    $(".mdfr_list_divlcs").each(function () {
      if ($(this).attr("data-mismatchpro") == "1") {
        over_allerror = "1";
        $(this).find(".mdfr_list_divlcs_error").show();
      } else {
        $(this).find(".mdfr_list_divlcs_error").hide();
      }
    });

    /*$('#comopo_mismatch_pro').val(over_allerror);*/

    this.updateProductPricefun();

    if (over_allerror == "1") {
      $(".compo_addcart_cls").hide();
    } else {
      $(".compo_addcart_cls").show();
    }
  }

  componentDidUpdate() {
    var TotalCnt = $(".modifierList").length;
    var modProductSlug = $("#modProductSlug").val();
    if (
      TotalCnt > 0 &&
      this.state.mdfApi_call === "Yes" &&
      this.state.selectedProSlug === modProductSlug
    ) {
      this.checkProductPrice();
    }

    var $_reactThis = this;
    var individualComboCnt = $(".individual_combo_mdf").length;
    if (individualComboCnt > 0) {
      $(".main_combo_div").each(function () {
        $_reactThis.setModifierValFun($(this).find(".components_selct"));
      });

      if ($(".components_selct").length > 0) {
        $(".components_selct").each(function () {
          $_reactThis.setOverallSubmdfrPrice($(this));
        });
      }
    }

    var indlMinMxComboCnt = $(".individual_combo_pro").length;
    if (indlMinMxComboCnt > 0) {
      $(".main_combo_div").each(function () {
        var minselectcombo_txt =
          $(this).data("minselectcombo") != ""
            ? $(this).data("minselectcombo")
            : "0";
        var combopriceapply_txt =
          $(this).data("combopriceapply") != ""
            ? $(this).data("combopriceapply")
            : "0";

        if (
          parseInt(minselectcombo_txt) === 0 ||
          parseInt(combopriceapply_txt) === 1
        ) {
          $(this).find(".combo_pro_price").show();
        } else {
          //  $(this).find(".combo_pro_price").hide();
        }
      });
    }

    var minmaxMainCnt = $(".minmax_maincombo_div").length;
    var chkAplyModfInMinmax = 0;
    if (minmaxMainCnt > 0) {
      chkAplyModfInMinmax = $(".minmax_maincombo_div").find(
        ".components_selctbox_cls"
      ).length;
      this.updateProductPricefun();
    }

    var singleSelectCompo = $(".components_selctbox_cls").length;
    if (
      singleSelectCompo > 0 &&
      ((minmaxMainCnt === 0 &&
        indlMinMxComboCnt === 0 &&
        individualComboCnt === 0) ||
        chkAplyModfInMinmax > 0)
    ) {
      if ($(".components_selct").length > 0) {
        $(".components_selct").each(function () {
          $_reactThis.setOverallSubmdfrPrice($(this));
        });
      }
    }
  }

  viewProDetail(productDetail, pro_cate_slug, pro_subcate_slug, event) {
    if (
      event !== "" &&
      typeof event !== "undefined" &&
      typeof event !== undefined
    ) {
      event.preventDefault();
    }

    var productSlug = productDetail.product_slug;

    if (productSlug !== "") {
      this.props.history.push(
        "/menu" +
          "/" +
          pro_cate_slug +
          "/" +
          pro_subcate_slug +
          "/" +
          productSlug
      );
    }
    return false;
  }

  /* Addon product Listing */
  addonProductListing() {
    var addonProductlst = this.props.addonproductlist;
    var clientProductStock = "";
    /* if (Object.keys(this.state.globalSettings).length > 0) {
      clientProductStock = this.state.globalSettings.client_product_stock;
    } */
    if (Object.keys(addonProductlst).length > 0) {
      if (Object.keys(addonProductlst[0].subcategorie).length > 0) {
        var slugType = "category";
        var slugValue = addonProductlst[0].subcategorie[0].pro_cate_slug;
        var pro_subcate_slug =
          addonProductlst[0].subcategorie[0].pro_subcate_slug;
        var addonProlstOnly = addonProductlst[0].subcategorie[0].products;
        var addonCommonImg = this.props.addonproductcommon.product_image_source;
        var tagImageSource = this.props.addonproductcommon.tag_image_source;
        const Phtml = addonProlstOnly.map((product, index) => (
          <div id={"proIndex-" + product.product_primary_id} key={index}>
            <div className="products-mayalso">
              <div className="products-image-div">
                {product.product_thumbnail !== "" ? (
                  <img
                    src={addonCommonImg + "/" + product.product_thumbnail}
                    alt="product"
                  />
                ) : (
                  <img src={noimage} alt="product" />
                )}
              </div>

              <div className="product-info-div">
                <div className="product-title-maindiv">
                  <div className="product-title">
                    <h3>
                      {product.product_alias !== ""
                        ? stripslashes(product.product_alias)
                        : stripslashes(product.product_name)}
                    </h3>
                  </div>
                </div>

                <div className="products-ordernow-action">
                  <div className="product-price">
                    <h3>{showPriceValue(product.product_price)}</h3>
                  </div>
                  {product.product_stock > 0 ||
                  product.product_stock === null ? (
                    product.product_type === "1" ? (
                      <a
                        className="button order_nowdiv smiple_product_lk disbl_href_action"
                        href="/"
                        onClick={this.viewProDetail.bind(
                          this,
                          product,
                          slugValue,
                          pro_subcate_slug
                        )}
                      >
                        Order Now
                      </a>
                    ) : (
                      <a
                        href="/"
                        onClick={this.viewProDetail.bind(
                          this,
                          product,
                          slugValue,
                          pro_subcate_slug
                        )}
                        title="Product Details"
                        id={"comboPro-" + product.product_slug}
                        className="button order_nowdiv compo_product_lk"
                      >
                        Order Now
                      </a>
                    )
                  ) : (
                    <a
                      className="button order_nowdiv disabled disbl_href_action"
                      href="/"
                    >
                      Sold Out
                    </a>
                  )}

                  <div className="addcart_row addcart_done_maindiv">
                    <div className="qty_bx">
                      <span
                        className="qty_minus"
                        /* onClick={this.proQtyAction.bind(
                        this,
                        product.product_primary_id,
                        "decr"
                      )} */
                      >
                        -
                      </span>
                      <input
                        type="text"
                        value={this.state.varSimpleProQty}
                        className="proqty_input"
                        readOnly
                      />
                      <span
                        className="qty_plus"
                        /*  onClick={this.proQtyAction.bind(
                        this,
                        product.product_primary_id,
                        "incr"
                      )} */
                      >
                        +
                      </span>
                    </div>
                    <button
                      className="button btn_black"
                      /*  onClick={this.addToCartSimple.bind(this, product, "done")} */
                    >
                      Done
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ));

        return Phtml;
      }
    } else {
      return false;
    }
  }

  sateValChange = (field, value) => {
    if (field === "cartflg") {
      this.setState({ cartTriggerFlg: value });
    }
    if (field === "view_pro_data" && value !== "") {
      this.setState(
        { viewProductSlug: value },
        function () {
          this.openProDetailPopup();
        }.bind(this)
      );
    }
    if (field === "cartList") {
      this.setState({ cartList: value });
    }
    if (field === "cartItemsLength") {
      this.setState({ cartItemsLength: value });
    }
  };

  render() {
    return (
      <div className="product-detail-page">
        <Header
          cartTriggerFlg={this.state.cartTriggerFlg}
          sateValChange={this.sateValChange}
          showCatryName={this.state.selectedCategoryName}
        />

        <div className="prodetailinner-main-div" id="ProductDetailMdl">
        <div className="container">
          {this.productDetailsMain()}
        </div>
        </div>
        <div id="dvLoading" className="dvLoadrCls"></div>
        {/* Footer section */}
        <Footer />
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  var proDateilArr = Array();
  var proCommonArr = Array();
  var prodetailstatus = "";
  if (Object.keys(state.productdetail).length > 0) {
    if (
      state.productdetail[0].status === "ok" &&
      Object.keys(state.productdetail[0].result_set).length > 0
    ) {
      proDateilArr = state.productdetail[0].result_set;
      proCommonArr = state.productdetail[0].common;
      prodetailstatus = "success";
    } else {
      prodetailstatus = "failure";
    }
  }

  var addonProArr = Array();
  var addonCommonArr = Array();
  if (Object.keys(state.addonproduct).length > 0) {
    if (
      state.addonproduct[0].status === "ok" &&
      Object.keys(state.addonproduct[0].result_set).length > 0
    ) {
      addonProArr = state.addonproduct[0].result_set;
      addonCommonArr = state.addonproduct[0].common;
    }
  }

  return {
    productdetail: proDateilArr,
    productcommon: proCommonArr,
    productdetailstatus: prodetailstatus,
    addonproductlist: addonProArr,
    addonproductcommon: addonCommonArr,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProductDetail: (proSlug) => {
      dispatch({ type: GET_PRODUCT_DETAIL, proSlug });
    },
    getAddonProList: (outletId) => {
      dispatch({ type: GET_ADDONPRODUCT, outletId });
    },
    getFavouriteproducts: (formPayload) => {
      dispatch({ type: GET_FAVOURITE, formPayload });
    },
  };
};
export default connect(mapStateTopProps, mapDispatchToProps)(ProductDetail);
